import styled from 'styled-components';
import { Header } from './components/header';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetSectionsQuery } from '../../services/api/vehicle-sections';
import { SectionsList } from './SectionsList';
import { CameraView } from './CameraView';
import { CameraViewContextProvider, useCameraViewContext } from './CameraView/context';
import { ImageNotesDialog } from './ImageNotesDialog';
import { CircularProgress } from '@material-ui/core';

const Wrapper = styled.div`
  padding: 16px;
  padding-top: 72px;
  background: #fff;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
`;

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const handleSubmit = () => {};

const ListerVehicleUploadV2 = () => {
  const { id: vehicleId } = useParams<{ id: string }>();

  const { data: sections, isLoading } = useGetSectionsQuery(Number(vehicleId), { skip: !vehicleId });

  console.log('ListerVehicleUploadV2');

  const initialValues = useMemo(() => {
    if (!sections) return { under_hood: { subsections: {}, images: [], label: '' } };
    return { ...sections, vehicleId: Number(vehicleId) };
  }, [sections]);

  if (isLoading) {
    return (
      <LoadingWrapper>
        <CircularProgress />
      </LoadingWrapper>
    );
  }

  return (
    <Form
      mutators={{
        ...arrayMutators
      }}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      subscription={{ submitting: true }}
    >
      {() => (
        <CameraViewContextProvider>
          <RenderForm />
        </CameraViewContextProvider>
      )}
    </Form>
  );
};

const RenderForm = () => {
  const { isOpened } = useCameraViewContext();
  if (isOpened) {
    return <CameraView />;
  }

  return (
    <Wrapper>
      <Header />
      <SectionsList />
      <ImageNotesDialog />
    </Wrapper>
  );
};

export default ListerVehicleUploadV2;
