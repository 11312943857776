import { useFieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { Checkbox, TextField } from '@material-ui/core';
import { Field, useField } from 'react-final-form';
import { composeValidators, maxLength } from 'utils/validators';
import { useRef } from 'react';
import { AddCheckboxIcon } from '../../../CreateVehicle/icons/AddCheckboxIcon';
import { RedInfoIcon } from '../../../CreateVehicle/icons/RedInfoIcon';
import { useAddCheckboxMutation } from 'services/api/vehicle-sections';

const EditingFieldWrapper = styled.div<{ hasError: boolean }>`
  display: flex;
  border-bottom: ${props => (props.hasError ? '1px solid rgba(175, 41, 48, 1)' : '1px solid rgba(204, 204, 204, 1)')};
  padding-left: 40px;
  gap: 8px;
  align-items: center;
  position: relative;
`;

const FieldError = styled.div`
  position: absolute;
  display: flex;
  font-size: 12px;
  align-items: center;
  color: rgba(175, 41, 48, 1);
  gap: 8px;
  right: 0;
  bottom: -26px;
`;

const StyledTextField = styled(TextField)`
  flex-grow: 1;
  & * input {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    color: #000;
  }
`;

const AddButtonWrapper = styled.div`
  margin-left: auto;
  margin-top: 8px;
`;

const maxLength20 = maxLength(20, 'Max. 25 characters');

type Props = {
  sectionId: string;
  name: string;
};

export const EditingCheckboxField = ({ sectionId, name }: Props) => {
  const { fields } = useFieldArray(`${sectionId}.checkboxes`, { subscription: { value: true } });
  const { input: editingInput } = useField(`${name}.isEditing`);

  const [addCheckbox] = useAddCheckboxMutation();

  const {
    input: { value: vehicleId }
  } = useField(`vehicleId`);

  const {
    input: { value: serverId }
  } = useField(`${sectionId}.id`);

  const {
    input: { value: label, onChange: changeLabel },
    meta
  } = useField(`${name}.label`);

  const {
    input: { value: isSelected }
  } = useField(`${name}.selected`);

  const { input: idInput } = useField(`${name}.id`);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const submitCheckbox = async () => {
    inputRef.current?.blur();
    if (meta.error) {
      return;
    }
    if (!label) {
      return;
    }
    const parsedLabel = label.trim();
    editingInput.onChange(false);

    changeLabel(parsedLabel);
    const response = await addCheckbox({
      vehicleId,
      sectionId: serverId,
      payload: {
        selected: isSelected,
        label: parsedLabel
      }
    });

    if ('data' in response) {
      changeLabel(parsedLabel);
      idInput.onChange(response.data.data.id);
    }
  };

  const duplicateValidator = (inputLabel: string) => {
    if (!inputLabel) return;
    const parsedLabel = inputLabel.trim();
    const isUnique = !fields.value.slice(0, -1).find(v => v.label === parsedLabel);
    if (!isUnique) {
      return 'Duplicate value';
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key !== 'Enter') return;
    if (!label || label.trim() === '') {
      const i = fields.value.length - 1;
      fields.remove(i);
    } else {
      submitCheckbox();
    }
  };

  const hasError = !!meta.error && !!meta.touched;

  return (
    <EditingFieldWrapper hasError={hasError}>
      <Field name={`${name}.selected`}>
        {props => (
          <Checkbox
            color="primary"
            style={{ width: '12px', height: '12px' }}
            checked={props.input.value}
            onChange={e => props.input.onChange(e.target.checked)}
          />
        )}
      </Field>
      <Field validate={composeValidators(maxLength20, duplicateValidator)} name={`${name}.label`}>
        {props => (
          <StyledTextField
            onKeyDown={handleKeyDown}
            InputProps={{
              disableUnderline: true
            }}
            inputRef={inputRef}
            {...props.meta}
            {...props.input}
          />
        )}
      </Field>

      <AddButtonWrapper onClick={submitCheckbox}>
        <AddCheckboxIcon />
      </AddButtonWrapper>
      {hasError && (
        <FieldError>
          <span>{meta.error}</span>
          <RedInfoIcon />
        </FieldError>
      )}
    </EditingFieldWrapper>
  );
};
