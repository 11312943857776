import { Field, FieldProps, FieldRenderProps, useField } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { ImageField } from '../../../../services/api/upload-vehicle';
import { NotesIcon } from '../../../CreateVehicle/icons/NotesIcon';
import { useEffect } from 'react';
import { closeNotesField, openNotesField, useActiveNotesField } from 'services/listerUploadVehicle';
import { useDispatch } from 'react-redux';
import { BinIcon } from 'pages/ListerVehicleUploadV2/components/photos';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ImageWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: auto;
  gap: 9px;
`;

const Image = styled.div<{ imageUrl: string }>`
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: 100% 120px;
  background-repeat: no-repeat;
  min-height: 120px;
  max-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ImageIndex = styled.div`
  border: 1px solid rgba(255, 255, 255, 1);
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
  width: 24px;
  height: 24px;
  text-align: center;
  vertical-align: middle;
  color: #fff;
  border-radius: 9999px;
`;

const TopImageRow = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  text-align: center;
  padding: 2px 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.06px;
  color: #fff;
`;

const NotesIconWrapper = styled.div`
  display: flex;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const NotesWrapper = styled.div`
  background: rgba(34, 34, 34, 0.04);
  padding: 8px;
  padding-right: 20px;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  color: #000;
  border-radius: 8px;
`;

type Props = {
  sectionName: string;
};

export const PhotosView = ({ sectionName }: Props) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <FieldArray name={`${sectionName}.images`}>
          {({ fields }) =>
            fields.map((f, i) => <Field name={f} component={props => <PhotoComponent {...props} index={i} />} />)
          }
        </FieldArray>
      </ImageWrapper>
      <NotesComponent />
    </Wrapper>
  );
};

const PhotoComponent = ({ input, index }: FieldRenderProps<ImageField> & { index: number }) => {
  const imageUrl = input.value.file ? URL.createObjectURL(input.value.file) : input.value.url;

  const isPlaceholder = !imageUrl;

  return (
    <Image imageUrl={imageUrl}>
      <TopImageRow>
        {!isPlaceholder && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => {}}>
            <BinIcon />
          </div>
        )}
        <NotesIconComponent fieldName={input.name} />
      </TopImageRow>
      <Label>{input.value.label}</Label>
    </Image>
  );
};

const NotesIconComponent = ({ fieldName }: { fieldName: string }) => {
  const dispatch = useDispatch();

  const activeNotesField = useActiveNotesField();

  const {
    input: { value }
  } = useField(`${fieldName}.notes`);

  const hasNotes = !!value;

  const toggleActiveNotesField = () => {
    if (!hasNotes) return;

    if (activeNotesField === fieldName) {
      dispatch(closeNotesField());
    } else {
      dispatch(openNotesField(fieldName));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(closeNotesField());
    };
  }, []);

  return (
    <NotesIconWrapper onClick={toggleActiveNotesField}>
      <NotesIcon hasNotes={hasNotes} isActive={activeNotesField === fieldName} />
    </NotesIconWrapper>
  );
};

const NotesComponent = () => {
  const activeNotesField = useActiveNotesField();
  console.log('notes component', activeNotesField);
  if (!activeNotesField) return null;

  return (
    <NotesWrapper>
      <Field name={`${activeNotesField}.notes`}>{props => <span>{props.input.value}</span>}</Field>
    </NotesWrapper>
  );
};
