import { dataURLtoFile } from 'utils';
import apiSlice from '..';
import { CheckboxField, ImageField, InteriorQuestions, SectionConfig, UnderHood } from './types';

export type SectionsOrder = Array<[keyof SectionConfig | 'dealer_comments', string[]]>;

export const defaultSectionOrder: SectionsOrder = [
  ['odb', ['odb_check_readiness']],
  [
    'underhood_mechanical',
    [
      'oil_or_dipstick',
      'noticeable_oil_leak',
      'transmission_dipstick',
      'coolant',
      'head_gasket_condition',
      'transmission_dipstick_smell',
      'underhood_equipment',
      'check_blow_by',
      'unhood_frame_check'
    ]
  ],
  ['tires_and_wheels', ['driver_front', 'driver_rear', 'passenger_rear', 'passenger_front']],
  [
    'exterior',
    [
      'exterior_pictures',
      'vin_plate_pictures',
      'license_plate',
      'keys',
      'under_body_and_frame',
      'trunk_and_rear_frame',
      'catalytic_converter_present',
      'suspension_alternations',
      'other_glass_damage',
      'windshield',
      'b_and_c_frame_check'
    ]
  ],
  ['title_issues', []],
  ['interior', ['interior_pictures', 'interior_functional']],
  ['power_equipment', []],
  [
    'test_drive',
    [
      'how_engine_runs',
      'engine_rev_up',
      'engine_light_acceleration',
      'engine_medium_acceleration',
      'engine_heavy_acceleration',
      'any_engine_noise',
      'exhaust_sounds_like',
      'how_transmission_shifts',
      'transmission_light_acceleration',
      'transmission_medium_acceleration',
      'transmission_heavy_acceleration',
      'transmission_shudder',
      'clutch_pedal',
      'clutch_engages_in_gear',
      'engaged_every_gear',
      'three_point_turn',
      'engage_in_low_4x4',
      'engage_in_high_4x4'
    ]
  ],
  ['dealer_comments', []]
];

type AddCheckboxArgs = {
  vehicleId: number;
  sectionId: string;
  payload: { selected: boolean; label: string };
};

type UpdateCheckboxArgs = {
  checkboxId: number;
  vehicleId: number;
  sectionId: number;
  payload: { selected?: boolean; notes?: string; label?: string };
};

type RemoveCheckboxArgs = {
  checkboxId: number;
  vehicleId: number;
  sectionId: number;
};

export const vehicleSectionsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getSectionConfig: builder.query<SectionConfig, void>({
      query: () => ({
        url: 'vehicles/sections/config',
        method: 'GET'
      }),
      transformResponse: (response: any) => response.data,
      keepUnusedDataFor: 3600
    }),
    getSectionsOrder: builder.query<SectionsOrder, void>({
      query: () => ({
        url: 'vehicles/upload/section-order',
        method: 'GET'
      }),
      transformResponse: (r: any) => r.data || defaultSectionOrder,
      keepUnusedDataFor: 3600
    }),
    updateSectionsOrder: builder.mutation<void, SectionsOrder>({
      query: sectionOrder => ({
        url: 'vehicles/upload/section-order',
        method: 'PUT',
        body: sectionOrder
      })
    }),
    getQuestions: builder.query<{ interior_questions: InteriorQuestions }, number>({
      query: vehicleId => ({
        url: `vehicles/${vehicleId}/sections/questions`,
        method: 'GET'
      }),
      transformResponse: (r: any) => r.data,
      keepUnusedDataFor: 0
    }),
    getSections: builder.query<{ under_hood: UnderHood }, number>({
      query: vehicleId => ({
        url: `vehicles/${vehicleId}/sections`,
        method: 'GET'
      }),
      transformResponse: (r: any) => r.data
    }),
    patchVehicleSection: builder.mutation<
      void,
      { vehicleId: number; sectionId: number; notes?: string; grade?: string }
    >({
      query: ({ vehicleId, sectionId, ...payload }) => ({
        url: `vehicles/${vehicleId}/sections/${sectionId}`,
        method: 'PATCH',
        body: payload
      })
    }),
    addCheckbox: builder.mutation<{ data: CheckboxField }, AddCheckboxArgs>({
      query: ({ vehicleId, sectionId, payload }) => ({
        url: `vehicles/${vehicleId}/sections/${sectionId}/checkboxes`,
        method: 'POST',
        body: payload
      })
    }),
    updateCheckbox: builder.mutation<void, UpdateCheckboxArgs>({
      query: ({ checkboxId, vehicleId, sectionId, payload }) => ({
        url: `vehicles/${vehicleId}/sections/${sectionId}/checkboxes/${checkboxId}`,
        method: 'PATCH',
        body: payload
      })
    }),
    removeCheckbox: builder.mutation<void, RemoveCheckboxArgs>({
      query: ({ checkboxId, vehicleId, sectionId }) => ({
        url: `vehicles/${vehicleId}/sections/${sectionId}/checkboxes/${checkboxId}`,
        method: 'DELETE'
      })
    }),
    uploadSectionImage: builder.mutation<
      { data: ImageField },
      {
        image: { url: string; notes: string; index?: number; type?: string; grade?: number; is_damaged?: boolean };
        vehicleId: number;
        sectionId: number;
      }
    >({
      query: ({ vehicleId, image, sectionId }) => {
        const formData = new FormData();
        const file = dataURLtoFile(image.url);
        formData.append(`file`, file);
        formData.append('notes', image.notes);
        if (image.index != null) {
          formData.append('index', image.index.toString());
        }
        if (image.grade != null) {
          formData.append('grade', image.grade.toString());
        }
        if (image.type) {
          formData.append('type', image.type);
        }
        if (image.is_damaged === true) {
          formData.append('is_damaged', 'true');
        }

        return {
          url: `vehicles/${vehicleId}/sections/${sectionId}/images`,
          method: 'POST',
          body: formData,
          formData: true
        };
      }
    }),
    removeSectionImage: builder.mutation<void, { imageId: number; vehicleId: number; sectionId: number }>({
      query: ({ vehicleId, imageId, sectionId }) => {
        return {
          url: `vehicles/${vehicleId}/sections/${sectionId}/images/${imageId}`,
          method: 'DELETE'
        };
      }
    }),
    updateSectionImageNotes: builder.mutation<
      void,
      { imageId: number; vehicleId: number; sectionId: number; notes?: string; grade?: number }
    >({
      query: ({ vehicleId, imageId, sectionId, notes, grade }) => {
        return {
          url: `vehicles/${vehicleId}/sections/${sectionId}/images/${imageId}`,
          method: 'POST',
          body: {
            notes,
            grade
          }
        };
      }
    })
  }),
  overrideExisting: true
});

export const {
  useGetSectionConfigQuery,
  useGetSectionsOrderQuery,
  useUpdateSectionsOrderMutation,
  useGetQuestionsQuery,
  usePatchVehicleSectionMutation,
  useAddCheckboxMutation,
  useRemoveCheckboxMutation,
  useUpdateCheckboxMutation,
  useGetSectionsQuery,
  useUploadSectionImageMutation,
  useRemoveSectionImageMutation,
  useUpdateSectionImageNotesMutation
} = vehicleSectionsApiSlice;

export default vehicleSectionsApiSlice;
