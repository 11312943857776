import styled from 'styled-components';
import { Level1Section } from '../Level1Section';
import { SectionKeys } from '../config';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const sectionIds = SectionKeys.map(([k]) => k);

export const SectionsList = () => {
  return (
    <SectionWrapper>
      {sectionIds.map(sectionId => (
        <Level1Section sectionId={sectionId} />
      ))}
    </SectionWrapper>
  );
};
