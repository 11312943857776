import styled from 'styled-components';

export const BaseSectionHeader = styled.div<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom-left-radius: ${props => (props.isExpanded ? '0' : '8px')};
  border-bottom-right-radius: ${props => (props.isExpanded ? '0' : '8px')};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: rgba(34, 90, 145, 1);
  padding: 8px 16px 8px 8px;
  opacity: 1;

  & > span {
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    color: #fff;
  }
`;
