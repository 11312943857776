import styled from 'styled-components';
import { Button, Dialog, Icon } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useField } from 'react-final-form';
import { Level1Labels } from '../config';
import { useCameraViewContext } from '../CameraView/context';
import { AllPhotosSection } from '../AllPhotosSection';
import { CompletedCarIcon } from '../icons/completedCar';
import { closeImageNotesDialog, useImageNotesDialogOpened } from 'services/listerUploadVehicle/v2';
import { useDispatch } from 'react-redux';
import { ImagePlaceholderIcon } from 'pages/CreateVehicle/icons/ImagePlaceholder';
import { NotesField } from '../components/notes';
import { useState } from 'react';
import { EditIcon } from 'components/Icons';
import { useUpdateSectionImageMutation } from 'services/api/upload-vehicle';
import { useUpdateSectionImageNotesMutation } from 'services/api/vehicle-sections';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px;
  gap: 8px;
`;

const StyledDialog = styled(Dialog)`
  z-index: 10000;

  .success-dialog {
    border-radius: 20px;
    background: #fff;
    margin: 18px;
    min-height: 435px;
    width: 100%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 10000;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const Title = styled.div`
  font-size: 22px;
  line-height: 25.78px;
  font-weight: 500;
  color: #000;
`;

const Image = styled.div<{ imageUrl: string; isPlaceholder: boolean }>`
  background-color: ${props => (props.isPlaceholder ? '#D9D9D9' : 'inherit')};
  background-image: ${({ imageUrl, isPlaceholder }) => (isPlaceholder ? 'none' : `url(${imageUrl})`)};
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 236px;
  max-height: 236px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CompletedButton = styled(Button)`
  border-radius: 6px;
  text-transform: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 18.75px;
  text-align: center;
  width: 100%;
  height: 44px;
`;

const PlaceholderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const NotesDisplayContainer = styled.div`
  background: #2222220a;
  border-radius: 8px;
  padding: 16px 48px 16px 12px;
  position: relative;
`;

const EditIconWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
`;

const Notes = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #000;
  line-break: anywhere;
`;

export const ImageNotesDialog = () => {
  const imageField = useImageNotesDialogOpened();
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeImageNotesDialog());
  };

  return (
    <StyledDialog
      onClose={close}
      onBackdropClick={close}
      open={!!imageField}
      classes={{ paperScrollPaper: 'success-dialog' }}
    >
      {imageField && <DialogContent imageField={imageField} />}
    </StyledDialog>
  );
};

const DialogContent = ({ imageField }: { imageField: string }) => {
  const [level1] = imageField.split('.');
  const [isEditing, setIsEditing] = useState(false);
  const {
    input: { value }
  } = useField(imageField);

  const dispatch = useDispatch();

  const {
    input: { value: serverId }
  } = useField(`${level1}.id`);

  const {
    input: { value: vehicleId }
  } = useField(`vehicleId`);

  const [updateImage] = useUpdateSectionImageNotesMutation();

  const close = () => {
    dispatch(closeImageNotesDialog());
  };

  const save = async () => {
    await updateImage({
      sectionId: serverId,
      vehicleId,
      imageId: value.id,
      notes: value.notes
    });

    close();
  };

  const isPlaceholder = !value.url;
  return (
    <Wrapper>
      <HeaderRow>
        <Title>{`${Level1Labels[level1]} Note`}</Title>
        <Icon onClick={close}>close</Icon>
      </HeaderRow>
      <Image isPlaceholder={isPlaceholder} imageUrl={value.url}>
        {isPlaceholder && (
          <PlaceholderWrapper>
            <ImagePlaceholderIcon />
          </PlaceholderWrapper>
        )}
      </Image>
      {isEditing ? (
        <NotesField onSubmit={() => setIsEditing(false)} skipSave sectionId={imageField} />
      ) : (
        <NotesDisplayContainer>
          <Notes>{value.notes || 'No notes yet...'}</Notes>
          <EditIconWrapper onClick={() => setIsEditing(true)}>
            <EditIcon />
          </EditIconWrapper>
        </NotesDisplayContainer>
      )}
      <ButtonWrapper>
        <CompletedButton onClick={close} type="button" variant="contained" color="secondary">
          Cancel
        </CompletedButton>
        <CompletedButton onClick={save} type="button" variant="contained" color="primary">
          Save
        </CompletedButton>
      </ButtonWrapper>
    </Wrapper>
  );
};
