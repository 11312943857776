import { useState } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { Header, IconWrapper, Level1Section, SectionWrapper } from '../Level1Section';
import { CollapseIcon } from 'components/Icons/CollapseIcon';
import { ExpandIcon } from 'components/Icons/ExpandIcon';
import { CameraIcon } from 'pages/ListerVehicleUpload/icons/Camera';
import { DragIndicator } from '@material-ui/icons';
import { useCameraViewContext } from '../CameraView/context';
import { useDamageImages, useDamageImageFields } from '../hooks/useDamagedImages';
import { AllPhotosHeader, AllPhotosSectionWrapper, PhotosWrapper } from '../AllPhotosSection';
import { EmptyImage, PhotoComponent } from '../components/photos';
import styled from 'styled-components';
import { Field } from 'react-final-form';

type Props = {
  sectionId: string;
};

const SubsectionWrapper = styled.div`
  background: #e9edf7;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
`;

export const DamageSection = ({ sectionId }: Props) => {
  const damagedImages = useDamageImages();

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(c => !c);
  };

  const fillColor = '#666666';

  const { openCameraView } = useCameraViewContext();

  return (
    <SectionWrapper isExpanded={isExpanded} isLevel2>
      <Header isLevel2 isExpanded={isExpanded}>
        <IconWrapper onClick={toggleExpanded}>
          {isExpanded ? <CollapseIcon fillColor={fillColor} /> : <ExpandIcon fillColor={fillColor} />}
        </IconWrapper>
        <span>{'Damage'}</span>
        <IconWrapper isLevel2 floatLeft onClick={() => openCameraView('exterior.damaged', damagedImages.length, true)}>
          <CameraIcon color={fillColor} />
        </IconWrapper>
        <IconWrapper isLevel2 id={`activator-damaged`}>
          <DragIndicator />
        </IconWrapper>
      </Header>
      {isExpanded && <AllDamagedPhotos />}
    </SectionWrapper>
  );
};

const themeOptions: any = {
  white: {
    background: '#fff',
    gradeBackground: '#2222220A',
    wrapperBackground: '#D7E7F3'
  },
  grey: {
    background: '#F5F5F5',
    gradeBackground: '#fff',
    wrapperBackground: '#F5F5F5'
  }
};

export const AllDamagedPhotos = ({ theme = 'white', collapsed = false }: { theme?: string; collapsed?: boolean }) => {
  const [isExpanded, setIsExpanded] = useState(!collapsed);

  const toggleExpanded = () => {
    setIsExpanded(c => !c);
  };

  const { background, wrapperBackground } = themeOptions[theme];
  const fillColor = 'rgba(153, 153, 153, 1)';

  const damagedImageFields = useDamageImageFields();

  return (
    <div style={{ padding: theme === 'white' ? '7px 8px' : 0, background: wrapperBackground, borderRadius: '8px' }}>
      <AllPhotosSectionWrapper withBorder={theme === 'white'} style={{ background }}>
        <AllPhotosHeader isExpanded={isExpanded} style={{ background }}>
          <IconWrapper onClick={toggleExpanded}>
            {isExpanded ? <CollapseIcon fillColor={fillColor} /> : <ExpandIcon fillColor={fillColor} />}
          </IconWrapper>
          <span>{`All Damage Pictures`}</span>
        </AllPhotosHeader>
        {isExpanded && (
          <PhotosWrapper style={{ background }}>
            {damagedImageFields.map(({ name, localRemove }) => (
              <Field
                key={name}
                name={name}
                component={props => (
                  <PhotoComponent
                    key={name}
                    localRemove={localRemove}
                    withUploadLabel={false}
                    displaySize="md"
                    {...props}
                  />
                )}
              />
            ))}
            <EmptyImage />
          </PhotosWrapper>
        )}
      </AllPhotosSectionWrapper>
    </div>
  );
};
