import { Button } from '@material-ui/core';
import { useCameraViewContext } from '../../context';
import { useCallback } from 'react';
import { useField } from 'react-final-form';
import styled from 'styled-components';
import { useFieldArray } from 'react-final-form-arrays';
import { useUploadSectionImageMutation } from 'services/api/vehicle-sections';
import { useDispatch } from 'react-redux';
import { snackShow } from 'actions';
import toast from 'react-hot-toast';

const Wrapper = styled.div`
  padding: 0px 16px;
`;

const StyledButton = styled(Button)`
  border-radius: 6px;
  text-transform: none;
  width: 100%;
  height: 44px;
`;

export const TakeNextButton = () => {
  const { level1SectionId, closeCameraView, changeIndex, currentImageSrc, imageIndex } = useCameraViewContext();

  const dispatch = useDispatch();

  const { fields: images } = useFieldArray(`${level1SectionId}.images`, {
    subscription: { value: true, length: true }
  });

  const {
    input: { value: vehicleId }
  } = useField('vehicleId');
  const { input } = useField(`${level1SectionId}.images.${imageIndex}.url`);
  const { input: idInput } = useField(`${level1SectionId}.images.${imageIndex}.id`);
  const {
    input: { value: serverId }
  } = useField(`${level1SectionId}.id`);
  const {
    input: { value: notes }
  } = useField(`${level1SectionId}.images.${imageIndex}.notes`);
  const {
    input: { value: grade }
  } = useField(`${level1SectionId}.images.${imageIndex}.grade`);
  const {
    input: { value: type }
  } = useField(`${level1SectionId}.images.${imageIndex}.type`);
  const {
    input: { value: isDamaged }
  } = useField(`${level1SectionId}.images.${imageIndex}.is_damaged`);
  const [, parsedType] = type?.split('.') || [];
  const { input: sectionGradeInput } = useField(`${level1SectionId}.subsections.${parsedType}.grade`);

  const [uploadImage] = useUploadSectionImageMutation();

  const label = 'Next';

  const handleNext = useCallback(async () => {
    const isTiresSection = level1SectionId?.includes('tires');
    const response = await uploadImage({
      image: {
        notes,
        url: currentImageSrc!,
        type: isTiresSection ? type : undefined,
        index: imageIndex,
        grade,
        is_damaged: isDamaged
      },
      vehicleId,
      sectionId: serverId
    });

    if ('data' in response) {
      idInput.onChange(response.data.data.id);
      input.onChange(currentImageSrc);
      changeIndex();
      toast.success('Image saved');
      if (isTiresSection && grade != null) {
        if (grade <= 2) {
          sectionGradeInput.onChange('poor');
        } else if (grade === 3) {
          sectionGradeInput.onChange('fair');
        } else {
          sectionGradeInput.onChange('good');
        }
      }
    } else {
      toast.error(`Could not save image`);
      dispatch(snackShow({ type: 'error', message: 'Error while uploading image' }));
    }
  }, [closeCameraView, currentImageSrc, input, images.length]);

  return (
    <Wrapper>
      <StyledButton color="primary" variant="contained" onClick={handleNext}>
        {label}
      </StyledButton>
    </Wrapper>
  );
};
