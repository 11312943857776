import styled from 'styled-components';
import { NotesIcon } from '../../icons/notes';
import { useField } from 'react-final-form';
import { useRef, useState } from 'react';
import { SendIcon } from '../../../CreateVehicle/icons/SendIcon';
import { usePatchVehicleSectionMutation } from 'services/api/vehicle-sections';

const NotesWrapper = styled.div`
  height: 50px;
  outline: 1px solid #999999;
  border-radius: 8px;
  background: #fff;
  padding: 8px;
  display: flex;
  align-items: center;
`;

const NotesInput = styled.input`
  border: none;
  font-size: 12px;
  line-height: 16px;
  width: 80%;
  font-weight: 500;
  color: #000;
  padding: 0;
`;

const IconWrapper = styled.div<{ withAutoMargin?: boolean }>`
  display: flex;
  margin-left: ${props => (props.withAutoMargin ? 'auto' : 0)};
`;

type Props = {
  sectionId: string;
  onSubmit?: () => void;
  skipSave?: boolean;
};

export const NotesField = ({ sectionId, onSubmit = () => {}, skipSave = false }: Props) => {
  const { input: notesInput } = useField(`${sectionId}.notes`);

  const ref = useRef<HTMLInputElement | null>(null);

  const [localNotes, setLocalNotes] = useState(notesInput.value);

  const {
    input: { value: serverId }
  } = useField(`${sectionId}.id`);

  const {
    input: { value: vehicleId }
  } = useField(`vehicleId`);

  const [saveMutation] = usePatchVehicleSectionMutation();

  const save = async () => {
    if (!vehicleId) return;
    await saveMutation({
      sectionId: serverId,
      notes: localNotes,
      vehicleId
    });
  };

  const setNotes = () => {
    notesInput.onChange(localNotes);
    onSubmit();
    if (!skipSave) {
      save();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setNotes();
      if (ref.current) {
        ref.current.blur();
      }
    }
  };

  return (
    <NotesWrapper>
      <IconWrapper>
        <NotesIcon />
      </IconWrapper>
      <NotesInput
        ref={ref}
        onKeyDown={handleKeyDown}
        value={localNotes}
        onChange={e => setLocalNotes(e.target.value)}
        autoFocus
      />
      <IconWrapper onClick={setNotes} withAutoMargin>
        <SendIcon />
      </IconWrapper>
    </NotesWrapper>
  );
};
