import styled from 'styled-components';
import { Level3Section } from '../Level3Section';
import { AllPhotosSection } from '../AllPhotosSection';
import { useField } from 'react-final-form';
import { Level1Section } from '../Level1Section';
import { DamageSection } from '../DamageSection';

const Wrapper = styled.div`
  background: #e9edf7;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
`;

type Props = { sectionId: string };

export const SubSectionsList = ({ sectionId }: Props) => {
  const {
    input: { value }
  } = useField(sectionId);
  const sectionKeys = Object.keys(value.subsections);
  const showAllPhotosSection = !['exterior'].includes(sectionId);
  const renderDamagedSection = ['exterior'].includes(sectionId);

  const background = sectionId !== 'exterior' && sectionId?.includes('exterior') ? '#D7E7F3' : '#e9edf7';

  return (
    <Wrapper style={{ background }}>
      {showAllPhotosSection && <AllPhotosSection theme="white" sectionId={sectionId} />}
      {sectionKeys.map((subSectionId, index) => {
        if (subSectionId === 'damaged') {
          return null;
        }
        if (Object.keys(value.subsections?.[subSectionId]?.subsections || {})?.length > 0) {
          return <Level1Section key={subSectionId} isLevel2 sectionId={`${sectionId}.subsections.${subSectionId}`} />;
        }

        return (
          <Level3Section
            key={subSectionId}
            index={index}
            sectionId={`${sectionId}.subsections.${subSectionId}`}
            theme="white"
          />
        );
      })}
      {renderDamagedSection && <DamageSection sectionId={sectionId} />}
    </Wrapper>
  );
};
