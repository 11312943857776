import styled from 'styled-components';
import { useCameraViewContext } from '../../context';
import { NotesIcon } from '../../../icons/notes';
import { useField } from 'react-final-form';

const NotesWrapper = styled.div`
  height: 40px;
  border: 1px solid #ffffff66;
  border-radius: 30px;
  padding: 16px 8px;
  width: 100%;
  background: #66666699;
  display: flex;
  align-items: center;
`;

const NotesInput = styled.input`
  border: none;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #ffffff;
  background: transparent;
  width: 100%;
  padding: 0;
`;

const IconWrapper = styled.div<{ withAutoMargin?: boolean }>`
  display: flex;
  margin-left: ${props => (props.withAutoMargin ? 'auto' : 0)};
`;

export const CameraNotes = () => {
  const { level1SectionId, imageIndex } = useCameraViewContext();

  const { input } = useField(`${level1SectionId}.images.${imageIndex}.notes`);

  return (
    <NotesWrapper>
      <IconWrapper>
        <NotesIcon />
      </IconWrapper>
      <NotesInput {...input} />
    </NotesWrapper>
  );
};
