import styled from 'styled-components';
import { Checkbox } from '@material-ui/core';
import { useCameraViewContext } from '../../context';
import { useField } from 'react-final-form';
import { useWindowSize } from 'usehooks-ts';

const DamageCheckboxWrapper = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 16px;
  bottom: 10px;
  padding: 4px 16px 4px 10px;
  display: flex;
  gap: 8px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
  color: #fff;
  z-index: 999;

  @media (orientation: landscape) {
    padding: 2px 8px;
    position: static;
    width: fit-content;
  }
`;

export const DamageCheckbox = () => {
  const { imageIndex, level1SectionId } = useCameraViewContext();
  const { input } = useField(`${level1SectionId}.images.${imageIndex}.is_damaged`);
  const { width = 0, height = 0 } = useWindowSize();
  return (
    <DamageCheckboxWrapper>
      <Checkbox
        checkedIcon={<CheckedIcon />}
        icon={<UnCheckedIcon />}
        style={{
          padding: 0,
          borderRadius: '4px',
          width: 24,
          height: 24
        }}
        inputProps={{
          style: {
            width: width > height ? '24px' : '24px',
            height: width > height ? '24px' : '24px'
          }
        }}
        value={String(!!input.value)}
        checked={input.value}
        onChange={e => {
          input.onChange(e.target.checked);
        }}
      />
      <span>Damage</span>
    </DamageCheckboxWrapper>
  );
};

const CheckedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.66667 4C5.95942 4 5.28115 4.28095 4.78105 4.78105C4.28095 5.28115 4 5.95942 4 6.66667V25.3333C4 26.0406 4.28095 26.7189 4.78105 27.219C5.28115 27.719 5.95942 28 6.66667 28H25.3333C26.0406 28 26.7189 27.719 27.219 27.219C27.719 26.7189 28 26.0406 28 25.3333V6.66667C28 5.95942 27.719 5.28115 27.219 4.78105C26.7189 4.28095 26.0406 4 25.3333 4H6.66667ZM22.6 13.0613C22.8502 12.8113 22.9908 12.4722 22.9909 12.1185C22.9911 11.7648 22.8507 11.4255 22.6007 11.1753C22.3507 10.9251 22.0115 10.7845 21.6578 10.7844C21.3041 10.7843 20.9649 10.9247 20.7147 11.1747L14.1147 17.7747L11.2867 14.9467C11.1629 14.8228 11.0159 14.7245 10.8541 14.6574C10.6923 14.5903 10.5189 14.5558 10.3438 14.5557C9.99011 14.5556 9.65085 14.696 9.40067 14.946C9.15048 15.196 9.00986 15.5352 9.00973 15.8889C9.0096 16.2426 9.14999 16.5818 9.4 16.832L13.0773 20.5093C13.2135 20.6456 13.3753 20.7537 13.5532 20.8274C13.7312 20.9012 13.922 20.9392 14.1147 20.9392C14.3073 20.9392 14.4981 20.9012 14.6761 20.8274C14.8541 20.7537 15.0158 20.6456 15.152 20.5093L22.6 13.0613Z"
      fill="white"
    />
  </svg>
);

const UnCheckedIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 5C5.44772 5 5 5.44772 5 6V13V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V13V6C19 5.44772 18.5523 5 18 5H6ZM3 6C3 4.34315 4.34315 3 6 3H18C19.6569 3 21 4.34315 21 6V13V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V13V6Z"
      fill="#fff"
    />
  </svg>
);
