import Webcam from 'react-webcam';
import styled from 'styled-components';
import { useCameraViewContext } from '../../context';
import { useWindowSize } from 'usehooks-ts';
import React, { useEffect, useState } from 'react';

const Wrapper = styled.div`
  width: 100%;
  aspect-ratio: 2 / 1;
  background: #000;
  position: relative;
  display: flex;

  @media (orientation: landscape) {
    margin-top: 0;
    height: 100%;
    aspect-ratio: unset;
  }
`;

const StyledWebcam = styled(Webcam)`
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;

  @media (orientation: landscape) {
    width: auto;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const StyledInfo = styled.div`
  color: white;
  position: absolute;
  font-size: 18px;
  font-weight: bold;
  top: 100px;
  left: 40px;
`;

type Props = {
  children: React.ReactNode;
};

export const CameraComponent = ({ children }: Props) => {
  const { cameraRef, state } = useCameraViewContext();
  const { width = 0, height = 1 } = useWindowSize();
  const isLandscape = width > height;

  const videoConstraints = {
    facingMode: 'environment',
    width: isLandscape ? 4032 : undefined,
    height: isLandscape ? 3024 : undefined,
    aspectRatio: isLandscape ? 3 / 4 : 1 / 2
  };

  return (
    <Wrapper id="camera-wrapper" style={{ display: state !== 'taking_photo' ? 'none' : 'flex' }}>
      <StyledWebcam
        audio={false}
        ref={cameraRef}
        id="camera"
        onUserMediaError={e => {
          alert(e);
        }}
        screenshotQuality={1}
        screenshotFormat={'image/jpeg'}
        hidden={state !== 'taking_photo'}
        videoConstraints={videoConstraints}
      />
      {children}
    </Wrapper>
  );
};
