import styled from 'styled-components';
import { useCameraViewContext } from '../../context';
import { useField } from 'react-final-form';
import ReactSelect from 'react-select';

const StyledSelect = styled(ReactSelect)`
  .tires-grade-select__control {
    background-color: #66666699;
    border-radius: 30px;
    border: 1px solid #ffffff66;
    width: 74px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none !important; /* Remove blue outline */
  }

  .tires-grade-select__control:hover {
    border: 1px solid #ffffff66;
  }

  .tires-grade-select__control--is-focused {
    border: 1px solid #ffffff66 !important;
    box-shadow: none !important;
  }

  .tires-grade-select__single-value {
    color: white;
    font-weight: bold;
  }

  .tires-grade-select__dropdown-indicator {
    color: white !important;
    padding-right: 8px;
  }

  .tires-grade-select__menu {
    background-color: #444;
    border-radius: 8px;
  }

  .tires-grade-select__indicator-separator {
    display: none;
  }

  .tires-grade-select__option {
    color: white;
    padding: 8px;
  }

  .tires-grade-select__option:hover {
    background-color: #555;
  }
`;

const options = Array.from(new Array(15)).map((_, i) => ({
  label: i,
  value: i
}));

export const CameraTiresGrade = () => {
  const { level1SectionId, imageIndex } = useCameraViewContext();

  const { input } = useField(`${level1SectionId}.images.${imageIndex}.grade`);

  return (
    <StyledSelect
      value={options.find(o => o.value === input.value)}
      onChange={(option: any) => input.onChange(option.value)}
      placeholder=""
      classNamePrefix={'tires-grade-select'}
      options={options}
      isSearchable={false}
    />
  );
};
