import DriverFront from './driver_front.svg';
import DriverRear from './driver_rear.svg';
import PassengerFront from './passenger_front.svg';
import PassengerRear from './passenger_rear.svg';
import Vin from './vin.svg';
import LicensePlate from './license_plate.svg';

export const TiresIconList = [
  <DriverFront />,
  <DriverRear />,
  <PassengerRear />,
  <PassengerFront />,
  <Vin />,
  <LicensePlate />
];
