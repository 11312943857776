import { Checkbox, Icon } from '@material-ui/core';
import { useState } from 'react';
import { Field, useField } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { NotesIcon } from '../../../CreateVehicle/icons/NotesIcon';
import { AddCheckboxIcon } from '../../../CreateVehicle/icons/AddCheckboxIcon';
import { DoubleCaretDown, DoubleCaretUp } from '../../../../components/Icons/DoubleCaret';
import { EditingCheckboxField } from './EditingCheckboxField';
import { useAddCheckboxMutation, useUpdateCheckboxMutation } from 'services/api/vehicle-sections';

const CheckboxToggleRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 8px;
`;

const ToggleLabel = styled.div`
  text-decoration: underline;
  color: #225a91;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
`;

const Container = styled.div`
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

type Props = {
  sectionId: string;
};

export const CheckboxQuestions = ({ sectionId }: Props) => {
  const [checkboxesVisible, setCheckboxesVisible] = useState(false);
  const toggle = () => setCheckboxesVisible(curr => !curr);
  const { fields } = useFieldArray(`${sectionId}.checkboxes`);
  return (
    <>
      <CheckboxToggleRow onClick={toggle}>
        <ToggleLabel>{checkboxesVisible ? 'Hide Checkboxes' : 'Unhide Checkboxes'}</ToggleLabel>
        <Icon style={{ display: 'flex' }}>{checkboxesVisible ? <DoubleCaretUp /> : <DoubleCaretDown />}</Icon>
      </CheckboxToggleRow>
      {checkboxesVisible && (
        <Container>
          {fields.map((name, i) => (
            <CheckboxField isLast={i === fields.length! - 1} sectionId={sectionId} name={name} />
          ))}
        </Container>
      )}
    </>
  );
};

type CheckboxProps = {
  sectionId: string;
  name: string;
  isLast: boolean;
};

const FieldWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  color: #666666;
`;

const AddButtonWrapper = styled.div`
  margin-left: auto;
  margin-top: 8px;
`;

export const CheckboxField = ({ sectionId, name, isLast }: CheckboxProps) => {
  const { fields } = useFieldArray(`${sectionId}.checkboxes`);

  const {
    input: { value: isEditing }
  } = useField(`${name}.isEditing`);

  const {
    input: { value: vehicleId }
  } = useField(`vehicleId`);

  const {
    input: { value: serverId }
  } = useField(`${sectionId}.id`);

  const {
    input: { value: checkboxId }
  } = useField(`${name}.id`);

  const [updateCheckbox] = useUpdateCheckboxMutation();

  if (isEditing) {
    return <EditingCheckboxField sectionId={sectionId} name={name} />;
  }

  return (
    <FieldWrapper>
      <Field name={`${name}.notes`}>
        {({ input: notesInput }) => (
          <Icon style={{ display: 'flex', width: '32px', height: '32px' }}>
            <NotesIcon hasNotes={!!notesInput.value} />
          </Icon>
        )}
      </Field>

      {/* <NotesField name={name} /> */}
      <Field name={`${name}.selected`}>
        {props => (
          <Checkbox
            color="primary"
            style={{ width: '24px', height: '24px', padding: 0 }}
            checked={props.input.value}
            onChange={e => {
              updateCheckbox({ vehicleId, sectionId: serverId, checkboxId, payload: { selected: e.target.checked } });
              props.input.onChange(e.target.checked);
            }}
          />
        )}
      </Field>
      <Field name={`${name}.label`}>{props => <Label>{props.input.value}</Label>}</Field>
      {isLast && (
        <AddButtonWrapper
          onClick={() =>
            fields.push({
              selected: true,
              label: '',
              notes: '',
              isEditing: true
            })
          }
        >
          <AddCheckboxIcon />
        </AddButtonWrapper>
      )}
    </FieldWrapper>
  );
};
