import React from 'react';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { TooltipProps } from '@material-ui/core/Tooltip';

function arrowGenerator() {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent #fff transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `#fff transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent #fff transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent #fff`
      }
    }
  };
}

const styles = (theme: any) => {
  return {
    lightTooltip: {
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 1)',
      boxShadow: theme.shadows[1]
    },
    uploadVehicleTooltip: {
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14px',
      backgroundColor: '#fff',
      color: 'rgba(72, 72, 72, 1)',
      border: '1px solid rgba(204, 204, 204, 1)',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      maxWidth: '365px'
    },
    arrowPopper: arrowGenerator(),
    arrow: {
      position: 'absolute',
      fontSize: 6,
      width: '3em',
      height: '3em',
      color: '#000',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid'
      }
    }
  };
};

export function getTextWidth(text: string, font = '16px Roboto') {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d')!;
  context.font = font;

  return context.measureText(text).width;
}

type Props = {
  children: JSX.Element;
  title: string;
  arrow?: boolean;
  classes: any;
  light?: boolean;
  maxWidth?: number;
  isTextOverflown?: boolean;
  titleStyle?: string;
} & TooltipProps;

const Tooltip = ({
  children,
  title,
  arrow = false,
  classes,
  light,
  maxWidth = 999,
  isTextOverflown = false,
  titleStyle = 'default',
  ...tooltipProps
}: Props) => {
  const arrowRef = React.useRef<HTMLSpanElement | null>(null);
  const parsedClasses = React.useMemo(() => {
    const res: ClassNameMap<string> = {};
    if (arrow) {
      res.popper = classes.arrowPopper;
    }
    if (light) {
      res.tooltip = classes.lightTooltip;
    }
    if (titleStyle === 'uploadVehicle') {
      res.tooltip = classes.uploadVehicleTooltip;
    }
    return res;
  }, [arrow, classes, light]);

  const parsedTitle = React.useMemo(() => {
    if (!arrow) return title;
    return (
      <React.Fragment>
        {title}
        <span className={classes.arrow} ref={arrowRef} />
      </React.Fragment>
    );
  }, [arrow, classes, title]);

  const PopperProps = React.useMemo(() => {
    if (!arrow) return undefined;
    return {
      popperOptions: {
        modifiers: {
          arrow: {
            enabled: Boolean(arrowRef.current),
            element: arrowRef.current
          }
        }
      }
    };
  }, [arrow]);

  const disableHoverListener = (() => {
    if (isTextOverflown) return false;
    return title === '' || title == null || getTextWidth(title) <= maxWidth;
  })();

  return (
    <MuiTooltip
      PopperProps={PopperProps}
      title={parsedTitle}
      classes={parsedClasses}
      disableHoverListener={disableHoverListener}
      {...tooltipProps}
    >
      {children}
    </MuiTooltip>
  );
};

export default withStyles(styles as any)(Tooltip);
