import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter, NavLink } from 'react-router-dom';
import { Menu, ClickAwayListener } from '@material-ui/core';

import DropdownArrow from '../../../img/down-arrow-dark.svg';

const StyledButton = styled.button`
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  margin-left: 24px;
  color: #212121;
  position: relative;
  background: none;
  border: 0;
  padding: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  text-transform: uppercase;
  span {
    width: 17px;
  }

  .dropdown-arrow {
    margin-left: 3px;

    &.open {
      transform: rotate(180deg);
    }
  }
  &:before {
    ${({ isActive }) =>
      isActive &&
      `
      content: '';
      width: 100%;
      height: 3px;
      background: #212121;
      position: absolute;
      top: -28px;
    `}
  }
  &:hover:before,
  &.active:before {
    content: '';
    width: 100%;
    height: 3px;
    background: #212121;
    position: absolute;
    top: -28px;
  }
  @media only screen and (min-width: 1300px) {
    margin-left: 42px;
  }

  @media only screen and (max-width: 1025px) {
    display: none;
  }
  > .menu-burger {
    @media only screen and (min-width: 1025px) {
      display: none;
    }
  }
`;

const StyledLinkMenuItem = styled(NavLink)`
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  padding: 12px 22px;
  color: #212121;
  position: relative;
  display: block;
  transition: all 250ms;

  &:hover {
    color: #3994de;
  }
`;

class DropDownList extends Component {
  state = {
    anchorEl: null,
    open: false
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleClickAway = () => {
    const { open: isOpen } = this.state;
    if (isOpen) {
      this.setState({
        open: false
      });
    }
  };

  render() {
    const { text, location, list } = this.props;
    const { open, anchorEl } = this.state;
    const isActiveButton = list.map(item => item.to).includes(location.pathname);
    return (
      <div>
        <ClickAwayListener onClickAway={this.handleClickAway}>
          <>
            {' '}
            <StyledButton
              aria-owns={open ? 'drop-down-menu' : null}
              aria-haspopup="true"
              isActive={isActiveButton}
              onClick={this.handleClick}
            >
              {text}
              <span className={`dropdown-arrow ${open ? 'open' : ''}`}>
                <DropdownArrow />
              </span>
            </StyledButton>
            <Menu
              id="drop-down-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={this.handleRequestClose}
              anchorOrigin={{ vertical: 30, horizontal: 'left' }}
              getContentAnchorEl={null}
              classes={{
                paper: 'dropdown'
              }}
            >
              {list.map(({ to, text: textLink }, index) => (
                <StyledLinkMenuItem key={index} to={to}>
                  {textLink}
                </StyledLinkMenuItem>
              ))}
            </Menu>
          </>
        </ClickAwayListener>{' '}
      </div>
    );
  }
}

export default withRouter(DropDownList);
