import moment from 'moment';

export { default as Api } from './api';
export { default as Auth } from './auth';
export { default as Auction } from './auction';

const MODE = import.meta.env.MODE;

export const dataURItoBlob = dataURI => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const dataURLtoFile = (dataurl, filename) => {
  console.log(dataurl, filename, 'dataUrlToFile');
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  let File = window.File;

  try {
    new File([], '');
  } catch (e) {
    File = class File extends Blob {
      constructor(chunks, filename, opts = {}) {
        super(chunks, opts);
        this.lastModifiedDate = new Date();
        this.lastModified = +this.lastModifiedDate;
        this.name = filename;
      }
    };
  }
  return new File([u8arr], filename, { type: mime });
};

export const getWindShieldTextValue = value => {
  if (value >= 50) {
    return 'Good';
  } else {
    return 'Poor';
  }
};

export const getSliderTextValue = (value, options = ['Excellent', 'Good', 'Fair', 'Poor']) => {
  let text;
  switch (true) {
    case value === 100:
      text = options[0];
      break;
    case value >= 66 && value < 100:
      text = options[1];
      break;
    case value >= 33 && value < 66:
      text = options[2];
      break;
    case value > 0 && value < 33:
      text = options[3];
      break;
    case value === 0:
      text = options[4] || 'N/A';
      break;
    default:
      text = options[4] || 'N/A';
  }
  return text;
};

export const directionLink = (fromUser, toUser) => {
  const saddr = `${fromUser.street},+${fromUser.city},+${fromUser.state},+${fromUser.zip}`;
  const daddr = `${toUser.street},+${toUser.city},+${toUser.state},+${toUser.zip}`;
  return `https://maps.google.com?saddr=${saddr}&daddr=${daddr}`;
};

export const convertToLocalTime = (date, short) => {
  const d = moment.utc(date).local();

  return short ? d.format('MM/DD/YY, hh:mm A') : d.format('dddd, MMMM DD, YYYY, hh:mm A');
};

export const generateRandomKey = () =>
  Math.random()
    .toString(36)
    .substring(2, 10);

export const numberFormatString = (value, prefix = '$') => {
  const formatedValue = value
    .toString()
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
    .trim();
  return `${prefix}${formatedValue}`;
};

export const debounce = (fn, time) => {
  let timeout;

  return function(...args) {
    // <-- not an arrow function
    const functionCall = () => fn.apply(this, args);

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
};

export const firstLetterUppercase = stringToUppercase =>
  stringToUppercase.charAt(0).toUpperCase() + stringToUppercase.slice(1);

export const normalizeFormattedField = value => (value || '').toString().replace(/[\D]/g, '');

export const cutTextElipsis = (text, length = 25) => (text?.length >= length ? `${text.substr(0, length)}...` : text);

export const minutesToMs = minutes => minutes * 60 * 1000;
export const msToMinutes = ms => ms / 60 / 1000;

export const formatCurrency = amount => {
  return Number(amount).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    trailingZeroDisplay: 'stripIfInteger'
  });
};
