import { useFieldArray } from 'react-final-form-arrays';

export const useDamageImages = () => {
  // todo: make generic if needed

  const { fields: tiresImages } = useFieldArray(`exterior.subsections.tires.images`, {
    subscription: { value: true }
  });
  const { fields: fitFinishFrameImages } = useFieldArray(`exterior.subsections.fit_finish_frame.images`, {
    subscription: { value: true }
  });
  const { fields: exteriorImages } = useFieldArray(`exterior.subsections.exterior.images`, {
    subscription: { value: true }
  });

  const damagedImages = [
    ...(tiresImages.value || []),
    ...(fitFinishFrameImages.value || []),
    ...(exteriorImages.value || [])
  ].filter(img => img.is_damaged);

  return damagedImages;
};

export const useDamageImageFields = () => {
  // todo: make generic if needed

  const { fields: tiresImages } = useFieldArray(`exterior.subsections.tires.images`, {
    subscription: { value: true }
  });
  const { fields: fitFinishFrameImages } = useFieldArray(`exterior.subsections.fit_finish_frame.images`, {
    subscription: { value: true }
  });
  const { fields: exteriorImages } = useFieldArray(`exterior.subsections.exterior.images`, {
    subscription: { value: true }
  });

  const damagedImagesFields = [];
  for (const i in tiresImages.value || []) {
    if (tiresImages.value[i].is_damaged && tiresImages.value[i].url) {
      damagedImagesFields.push({
        name: `${tiresImages.name}[${i}]`,
        localRemove: () => tiresImages.remove(Number(i))
      });
    }
  }

  for (const i in fitFinishFrameImages.value || []) {
    if (fitFinishFrameImages.value[i].is_damaged) {
      damagedImagesFields.push({
        name: `${fitFinishFrameImages.name}[${i}]`,
        localRemove: () => fitFinishFrameImages.remove(Number(i))
      });
    }
  }

  for (const i in exteriorImages.value || []) {
    if (exteriorImages.value[i].is_damaged) {
      damagedImagesFields.push({
        name: `${exteriorImages.name}[${i}]`,
        localRemove: () => exteriorImages.remove(Number(i))
      });
    }
  }

  return damagedImagesFields;
};
