import styled from 'styled-components';
import { useCameraViewContext } from '../../context';
import { useInitialCameraViewIndex } from 'pages/ListerVehicleUploadV2/hooks/useInitialCameraViewIndex';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;

  @media (orientation: landscape) {
    padding: 0;
    margin-top: 8px;
  }
`;

const Tab = styled.div<{ isSelected: boolean }>`
  flex: 1 1 100%;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: ${props => (props.isSelected ? '#fff' : '#999999')};
  padding-bottom: 18px;
  border-bottom: ${props => (props.isSelected ? '2px solid #3994DE' : 'none')};

  @media (orientation: landscape) {
    padding: 0;
    min-width: fit-content;
    padding: 0px 20px 8px;
  }
`;

const tabs = [
  { value: 'tires', label: 'Tires' },
  { value: 'fit_finish_frame', label: 'Fit & Finish & Frame' },
  { value: 'exterior', label: 'Exterior' },
  { value: 'damaged', label: 'Damage' }
];

export const Level2Selection = () => {
  return (
    <Wrapper>
      {tabs.map(tab => (
        <TabComponent tab={tab} key={tab.value} />
      ))}
    </Wrapper>
  );
};

type TabProps = {
  tab: {
    value: string;
    label: string;
  };
};

const TabComponent = ({ tab }: TabProps) => {
  const { level1SectionId, openCameraView } = useCameraViewContext();

  const [level1, _, level3] = level1SectionId?.split('.') || [];

  const tabSectionId = `${level1}.subsections.${tab.value}`;
  const initialIndex = useInitialCameraViewIndex(tabSectionId);

  const changeTab = () => {
    openCameraView(tab.value === 'damaged' ? 'exterior.damaged' : tabSectionId, initialIndex, tab.value === 'damaged');
  };

  return (
    <Tab
      onClick={changeTab}
      isSelected={tab.value === 'damaged' ? level1SectionId === 'exterior.damaged' : level3 === tab.value}
    >
      {tab.label}
    </Tab>
  );
};
