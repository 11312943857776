import styled from 'styled-components';
import { CameraHeader } from './components/header';
import { CameraComponent } from './components/imageCamera';
import { CameraNotes } from './components/notes';
import { DamagedListController, ImageListController } from './components/imageList';
import { useCameraViewContext } from './context';
import { TakePhoto } from './components/takePhoto';
import { ImagePreview } from './components/imagePreview';
import { HorizontalControls } from './components/controls';
import { DamageCheckbox } from './components/damage_checkbox';
import { Counter } from './components/counter';
import { HorizontalNextButton } from './components/takeNext/horizontalNext';
import { HorizontalOpenQuestionsButton } from './components/questionsButton/horizontalQuestions';
import { ConfirmDialog } from '../ConfirmDialog';
import { InFormQuestionsDialog } from '../InFormQuestionsDialog';
import { ImageNotesDialog } from '../ImageNotesDialog';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`;

const TakePhotoWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 56px;
  height: 100%;
  width: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);

  & div {
    transform: translateY(-61px);
  }
`;

const CameraNotesWrapper = styled.div`
  width: 100%;
  height: 66px;
  background: rgba(0, 0, 0, 0.7);
  padding: 8px 16px 0px 16px;
`;

const Panels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding-left: 16px;
  padding-right: 16px;
`;

const LeftPanel = styled.div`
  padding-bottom: 18px;
  padding-left: env(safe-area-inset-left, 0);
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const RightPanel = styled.div`
  padding-bottom: 12px;
  gap: 14px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

export const HorizontalView = () => {
  const { state, isDamageSection } = useCameraViewContext();
  if (isDamageSection) {
    return <HorizontalViewDamaged />;
  }
  
  return (
    <Wrapper>
      <ImageNotesDialog />
      <CameraComponent>
        <CameraHeader />
        <TakePhotoWrapper>{!isDamageSection && <TakePhoto />}</TakePhotoWrapper>
      </CameraComponent>
      {state === 'confirming_photo' && <ConfirmingPhotoView />}
      <InFormQuestionsDialog />
      <ConfirmDialog />
    </Wrapper>
  );
};

const HorizontalViewDamaged = () => {
  return (
    <Wrapper>
      <CameraHeader />
      <DamagedListController />
    </Wrapper>
  );
};

const ConfirmingPhotoView = () => {
  const { isDamageSection } = useCameraViewContext();
  return (
    <ImagePreview>
      <CameraHeader />
      {isDamageSection ? <DamagedListController /> : <ImageListController />}
      <Panels>
        <LeftPanel>
          <HorizontalControls />
          <DamageCheckbox />
        </LeftPanel>
        <RightPanel>
          <HorizontalNextButton />
          <Counter />
        </RightPanel>
      </Panels>
      <CameraNotesWrapper>
        <CameraNotes />
      </CameraNotesWrapper>
    </ImagePreview>
  );
};
