import { Icon } from '@material-ui/core';
import styled from 'styled-components';
import { useField } from 'react-final-form';
import { useCameraViewContext } from '../../context';
import { Level1Labels } from '../../../config';
import { GalleryIcon } from 'pages/CreateVehicle/icons/GalleryIcon';
import { QuizIcon } from 'pages/ListerVehicleUploadV2/icons/quizIcon';
import { Level2Selection } from '../level2Selection';
import { useWindowSize } from 'usehooks-ts';

const Wrapper = styled.div`
  width: 100%;
  height: 69px;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0 24px 0 24px;
  gap: 16px;
  background: rgba(0, 0, 0, 0.7);

  @media (orientation: landscape) {
    height: 56px;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionLabel = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 21.09px;
  color: #fff;
`;

const MuiIcon = styled(Icon)<{ floatLeft?: boolean }>`
  color: #fff;
  margin-left: ${props => (props.floatLeft ? 'auto' : '0')};
`;

const IconWrapper = styled.div<{ withMargin?: boolean }>`
  margin-left: ${props => props.withMargin ? 'auto' : 0};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media (orientation: portrait) {
    & span:first-child {
      display: none;
    }
  }
`;

const Level2SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const CameraHeader = () => {
  const { level1SectionId, closeCameraView, openConfirmDialog, currentImageSrc, imageIndex } = useCameraViewContext();

  const {
    input: { value: label }
  } = useField(`${level1SectionId}.label`);
  const { input } = useField(`${level1SectionId}.images.${imageIndex}.url`);
  const openQuestions = () => {
    if (currentImageSrc) {
      input.onChange(currentImageSrc);
    }
    openConfirmDialog();
  };

  const close = () => {
    closeCameraView();
  };

  const parsedLabel = (() => {
    if (level1SectionId === 'interior_questions') {
      return 'Interior';
    }
    if (level1SectionId === 'exterior.damaged') {
      return 'Damage';
    }

    return label;
  })();

  const { width, height } = useWindowSize();
  const isLandscape = width > height;
  const showLevel2Selection = isLandscape && level1SectionId?.startsWith('exterior');

  return (
    <Wrapper>
      <MuiIcon onClick={close}>close</MuiIcon>
      <LabelWrapper>
        <SectionLabel>{parsedLabel}</SectionLabel>
      </LabelWrapper>
      {showLevel2Selection && <Level2SectionWrapper>
        <Level2Selection />
        </Level2SectionWrapper>}
      <IconWrapper withMargin={!showLevel2Selection}>
        <MuiIcon onClick={openQuestions}>
          <QuizIcon />
        </MuiIcon>
        <MuiIcon>
          <GalleryIcon />
        </MuiIcon>
      </IconWrapper>
    </Wrapper>
  );
};
