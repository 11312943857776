import styled from 'styled-components';
import { useCameraViewContext } from '../../context';

const Wrapper = styled.div`
  width: 40px;
  height: 40px;
  outline: 3px solid #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16px;
  bottom: 16px;

  & > span {
    color: #fff;
    font-size: 17px;
    font-weight: 700;
    line-height: 22px;
  }

  @media (orientation: landscape) {
    position: static;
  }
`;

export const Counter = () => {
  const { imageIndex } = useCameraViewContext();

  return (
    <Wrapper>
      <span>{imageIndex + 1}</span>
    </Wrapper>
  );
};
