import { useHorizontalScrollFade } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { Field, FieldInputProps, useField } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { TiresIconList } from './icons';
import { useCameraViewContext } from '../../CameraView/context';
import { useRemoveSectionImageMutation } from 'services/api/vehicle-sections';
import toast from 'react-hot-toast';
import { BinIcon } from 'components/Icons';

const Container = styled.div<{ showAfterFade: boolean; showBeforeFade: boolean }>`
  margin: 0;
  padding: 0;
  overflow: hidden;
  overflow-x: auto;
  width: 100%;
  display: flex;
  gap: 6px;

  @media (orientation: landscape) {
    justify-content: center;
  }

  &:before {
    content: '';
    display: ${props => (props.showBeforeFade ? 'block' : 'none')};
    top: 0;
    left: 0;
    width: 100px;
    position: absolute;
    z-index: 999;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  }

  &:after {
    content: '';
    display: ${props => (props.showAfterFade ? 'block' : 'none')};
    right: 0;
    top: 0;
    width: 100px;
    position: absolute;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Image = styled.div<{ imageUrl: string; isPlaceholder: boolean; isSelected: boolean }>`
  background-color: ${props => {
    if (!props.isPlaceholder) return 'inherit';
    return props.isSelected ? '#CCCCCC' : '#666666';
  }};
  background-image: ${({ imageUrl, isPlaceholder }) => (isPlaceholder ? 'none' : `url(${imageUrl})`)};
  background-size: 100% 100%;
  min-height: 97px;
  max-height: 97px;
  background-repeat: no-repeat;
  aspect-ratio: 1;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  @media (orientation: landscape) {
    border: 2px solid #999999;
    min-height: 92px;
    max-height: 92px;
  }
`;

export const TiresImageCarousel = () => {
  const { fields } = useFieldArray(`exterior.subsections.tires.images`);

  const ref = useRef<HTMLDivElement>(null);

  const { showBeforeFade, showAfterFade } = useHorizontalScrollFade(ref, {
    beforeFadeThreshold: 50
  });

  return (
    <Container ref={ref} showBeforeFade={showBeforeFade} showAfterFade={showAfterFade}>
      {fields.map((field, i) => (
        <Field name={field}>{fieldProps => <TiresImageComponent index={i} {...fieldProps.input} />}</Field>
      ))}
    </Container>
  );
};

const BinIconWrapper = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  top: 4px;
  right: 4.6px;
  & svg {
    width: 12px;
    height: 12px;
  }
`;

const TiresImageComponent = ({ index, ...input }: FieldInputProps<any, HTMLElement> & { index: number }) => {
  const { level1SectionId, removeImage, setIndex, imageIndex } = useCameraViewContext();
  const { fields } = useFieldArray(`${level1SectionId}.images`);
  const [removeImageMutation] = useRemoveSectionImageMutation();

  const isSelected = index === imageIndex;

  const handleChangeIndex = () => setIndex(index);

  const {
    input: { value: vehicleId }
  } = useField(`vehicleId`);

  const {
    input: { value: serverId }
  } = useField(`${level1SectionId}.id`);

  const handleRemove = async () => {
    const response = await removeImageMutation({
      vehicleId,
      sectionId: serverId,
      imageId: input.value.id
    });

    if ('data' in response) {
      toast.success('Image removed');
      fields.update(index, { ...fields.value[index], url: '', url_thumb: '' });
      removeImage();
    } else {
      toast.error('Failed to remove image');
    }
  };

  return (
    <Image
      onClick={handleChangeIndex}
      imageUrl={input.value.url}
      isSelected={isSelected}
      isPlaceholder={!input.value.url}
    >
      {!input.value.url ? (
        TiresIconList[index]
      ) : (
        <BinIconWrapper onClick={handleRemove}>
          <BinIcon />
        </BinIconWrapper>
      )}
    </Image>
  );
};
