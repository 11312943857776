import { createContext, createRef, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Webcam from 'react-webcam';
import { snackShow } from '../../../actions';

type State = 'taking_photo' | 'confirming_photo';

type CameraViewContext = {
  isOpened: boolean;
  level1SectionId: string | null;
  isDamageSection: boolean;
  openCameraView: (level1SectionId: string, index: number, isDamageSection?: boolean) => void;
  closeCameraView: () => void;
  state: State;
  confirmDialogOpened: boolean;
  cameraRef: React.RefObject<Webcam>;
  currentImageSrc: string | null;
  takeImage: () => void;
  // changeSection: (s: string) => void;
  changeIndex: () => void;
  setIndex: (newIndex: number) => void;
  imageIndex: number;
  closeConfirmDialog: () => void;
  openConfirmDialog: () => void;
  retakeImage: () => void;

  successErrorDialogOpened: 'success' | 'error' | null;
  openSuccessErrorDialog: (type: 'success' | 'error') => void;
  closeSuccessErrorDialog: () => void;
  removeImage: () => void;

  showHelperText: boolean;
  setShowHelperText: React.Dispatch<React.SetStateAction<boolean>>;
};

const defaultValue: CameraViewContext = {
  isOpened: false,
  level1SectionId: null,
  state: 'taking_photo',
  confirmDialogOpened: false,
  isDamageSection: false,
  openCameraView: () => {},
  closeCameraView: () => {},
  cameraRef: createRef(),
  currentImageSrc: null,
  takeImage: () => {},
  // changeSection: () => {},
  imageIndex: 0,
  changeIndex: () => {},
  setIndex: () => {},
  closeConfirmDialog: () => {},
  openConfirmDialog: () => {},
  retakeImage: () => {},
  successErrorDialogOpened: null,
  openSuccessErrorDialog: () => {},
  closeSuccessErrorDialog: () => {},
  removeImage: () => {},

  showHelperText: false,
  setShowHelperText: () => {}
};

const CameraViewContext = createContext(defaultValue);

export const CameraViewContextProvider = ({ children }: { children: React.ReactNode }) => {
  const cameraRef = useRef<Webcam>();
  const [isDamageSection, setIsDamageSection] = useState(false);
  const [level1SectionId, setLevel1SectionId] = useState<string | null>(null);
  const [isOpened, setIsOpened] = useState(false);
  const [currentImageSrc, setCurrentImageSrc] = useState<string | null>(null);
  const [imageIndex, setImageIndex] = useState(0);
  const [confirmDialogOpened, setConfirmDialogOpened] = useState(false);
  const [successErrorDialogOpened, setSuccessErrorDialogOpened] = useState<'success' | 'error' | null>(null);

  const [showHelperText, setShowHelperText] = useState(false);

  const [state, setState] = useState<State>('taking_photo');

  const dispatch = useDispatch();

  const openCameraView = (level1SectionId: string, initialIndex: number, damageSection = false) => {
    setLevel1SectionId(level1SectionId);
    setImageIndex(initialIndex);
    setState('taking_photo');
    setIsOpened(true);
    setIsDamageSection(damageSection);
  };

  const closeCameraView = () => {
    setIsOpened(false);
  };

  const takeImage = () => {
    if (!cameraRef.current) {
      dispatch(snackShow({ type: 'error', message: 'Error while taking photo' }));
      return;
    }

    // const dataURL = cameraRef.current.getScreenshot();

    const video = document.querySelector('video');
    if (!video) {
      return;
    }
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const dataURL = canvas.toDataURL('image/jpeg', 1.0);

    setCurrentImageSrc(dataURL);
    setState('confirming_photo');
  };

  const changeIndex = () => {
    setCurrentImageSrc(null);
    setState('taking_photo');
    setImageIndex(curr => curr + 1);
  };

  const setIndex = (newIndex: number) => {
    setCurrentImageSrc(null);
    setState('taking_photo');
    setImageIndex(newIndex);
  };

  const removeImage = () => {
    setImageIndex(curr => curr - 1);
  };

  const retakeImage = () => {
    setCurrentImageSrc(null);
    setState('taking_photo');
  };

  const openConfirmDialog = () => setConfirmDialogOpened(true);
  const closeConfirmDialog = () => setConfirmDialogOpened(false);

  const openSuccessErrorDialog = (type: 'success' | 'error') => setSuccessErrorDialogOpened(type);
  const closeSuccessErrorDialog = () => setSuccessErrorDialogOpened(null);

  return (
    <CameraViewContext.Provider
      value={{
        // selectedSectionId,
        level1SectionId,
        isOpened,
        // availableLevel2Sections,
        openCameraView,
        closeCameraView,
        isDamageSection,
        state,
        cameraRef: cameraRef as React.RefObject<Webcam>,
        takeImage,
        currentImageSrc,
        // changeSection,
        changeIndex,
        setIndex,
        imageIndex,
        confirmDialogOpened,
        openConfirmDialog,
        closeConfirmDialog,
        retakeImage,
        successErrorDialogOpened,
        openSuccessErrorDialog,
        closeSuccessErrorDialog,
        removeImage,
        setShowHelperText,
        showHelperText
      }}
    >
      {children}
    </CameraViewContext.Provider>
  );
};

export const useCameraViewContext = () => {
  const context = useContext(CameraViewContext);
  return context;
};
