import { createContext, createRef, useContext, useEffect, useRef, useState } from 'react';
import { SectionConfig } from '../../../services/api/vehicle-sections/types';
import Webcam from 'react-webcam';
import { useDispatch } from 'react-redux';
import { snackShow } from '../../../actions';
import { useFieldArray } from 'react-final-form-arrays';
import { dataURItoFile } from 'utils/image';
import { useForm } from 'react-final-form';
import { useWindowSize } from 'usehooks-ts';

type ProviderProps = {
  sectionId: keyof SectionConfig;
  subSectionId: string;
  children: React.ReactNode;
};

type ImageUploadState = 'taking_photo' | 'confirming_photo';

type ImageUploadContext = {
  sectionId: keyof SectionConfig;
  subSectionId: string;
  currentImageIndex: number;
  state: ImageUploadState;
  cameraRef: React.RefObject<Webcam>;

  currentImageSrc: string | null;

  takeImage: () => void;
  confirmImage: () => void;
  retakeImage: () => void;
};

const defaultValue: ImageUploadContext = {
  sectionId: 'odb',
  subSectionId: 'odb_category',
  currentImageIndex: 0,
  state: 'taking_photo',
  cameraRef: createRef<Webcam>(),

  currentImageSrc: null,

  takeImage: () => {},
  confirmImage: () => {},
  retakeImage: () => {}
};

const ImageUploadContext = createContext(defaultValue);

export const ImageUploadContextProvider = ({ sectionId, subSectionId, children }: ProviderProps) => {
  const cameraRef = useRef<Webcam>();
  const [currentImageSrc, setCurrentImageSrc] = useState<string | null>(null);
  const [state, setState] = useState<ImageUploadState>('taking_photo');
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

  const { width = 0, height = 1 } = useWindowSize();

  const dispatch = useDispatch();

  const { fields } = useFieldArray(sectionId && subSectionId ? `${sectionId}.${subSectionId}.images` : '', {
    subscription: { value: true }
  });
  const form = useForm();

  const fullReset = () => {
    setState('taking_photo');
    setCurrentImageIndex(0);
    setCurrentImageSrc(null);
  };

  useEffect(() => {
    fullReset();
  }, [sectionId, subSectionId]);

  const takeImage = () => {
    if (!cameraRef.current) {
      dispatch(snackShow({ type: 'error', message: 'Error while taking photo' }));
      return;
    }

    const video = document.querySelector('video');
    if (!video) {
      return;
    }
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const dataURL = canvas.toDataURL('image/jpeg', 1.0);

    setCurrentImageSrc(dataURL);
    setState('confirming_photo');
  };

  const confirmImage = () => {
    if (!currentImageSrc || state !== 'confirming_photo') return null;
    const copy = [...fields.value];
    if (copy[currentImageIndex]) {
      copy[currentImageIndex] = { ...copy[currentImageIndex], file: dataURItoFile(currentImageSrc) };
    } else {
      copy[currentImageIndex] = { file: dataURItoFile(currentImageSrc), type: 'user_defined' };
    }
    form.change(`${sectionId}.${subSectionId}.images`, copy);
    setCurrentImageIndex(c => c + 1);
    setCurrentImageSrc(null);
    setState('taking_photo');
  };

  const retakeImage = () => {
    if (!currentImageSrc || state !== 'confirming_photo') return null;
    setCurrentImageSrc(null);
    setState('taking_photo');
  };

  return (
    <ImageUploadContext.Provider
      value={{
        currentImageIndex,
        currentImageSrc,
        sectionId,
        subSectionId,
        cameraRef: cameraRef as React.RefObject<Webcam>,
        state,
        takeImage,
        confirmImage,
        retakeImage
      }}
    >
      {children}
    </ImageUploadContext.Provider>
  );
};

export const useImageUploadContext = () => {
  const context = useContext(ImageUploadContext);
  return context;
};
