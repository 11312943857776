import styled from 'styled-components';
import { CameraHeader } from './components/header';
import { CameraComponent } from './components/imageCamera';
import { CameraNotes } from './components/notes';
import { TakeNextButton } from './components/takeNext';
import { OpenQuestionsButton } from './components/questionsButton';
import { DamagedListController, ImageListController } from './components/imageList';
import { HelperText } from './components/helperText';
import { useCameraViewContext } from './context';
import { DamageCheckbox } from './components/damage_checkbox';
import { Counter } from './components/counter';
import { TakePhoto } from './components/takePhoto';
import { ImagePreview } from './components/imagePreview';
import { VerticalControls } from './components/controls';
import { InFormQuestionsDialog } from '../InFormQuestionsDialog';
import { ConfirmDialog } from '../ConfirmDialog';
import { HelperTextVerticalToggler } from './components/helperText/helperTextVerticalToggler';
import { ImageNotesDialog } from '../ImageNotesDialog';
import { Level2Selection } from './components/level2Selection';
import { CameraTiresGrade } from './components/cameraTiresGrade';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  padding-bottom: 66px;
`;

const TextWrapper = styled.div`
  padding: 6px 10px 10px 16px;
`;

const TakePictureWrapper = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Space = styled.div`
  height: 12px;
`;

const NotesRow = styled.div`
  display: flex;
  gap: 8px;
  margin: 8px 16px 0px 16px;
`;

export const VerticalView = () => {
  return (
    <Wrapper>
      <UpperPart />
      <LowerPart />
    </Wrapper>
  );
};

const UpperPart = () => {
  const { level1SectionId, isDamageSection } = useCameraViewContext();
  const showLevel2Selection = level1SectionId?.startsWith('exterior');

  return (
    <>
      <ImageNotesDialog />
      <CameraHeader />
      {showLevel2Selection && <Level2Selection />}
      {isDamageSection ? <DamagedListController /> : <ImageListController />}
    </>
  );
};

const LowerPart = () => {
  const { state, isDamageSection, level1SectionId } = useCameraViewContext();

  if (isDamageSection) {
    return null;
  }

  return (
    <>
      <>
        <Space />
        <CameraComponent>
          <DamageCheckbox />
          {level1SectionId === 'under_hood' && <HelperTextVerticalToggler />}
          <Counter />
        </CameraComponent>

        {state === 'taking_photo' ? <TakePhotoView /> : <ConfirmingPhotoView />}
        <InFormQuestionsDialog />
        <ConfirmDialog />
      </>
    </>
  );
};

const TakePhotoView = () => {
  const { level1SectionId, imageIndex } = useCameraViewContext();
  const isTires = level1SectionId?.includes('tires');
  return (
    <>
      <NotesRow>
        <CameraNotes />
        {isTires && [0, 2, 3, 4].includes(imageIndex) && <CameraTiresGrade />}
      </NotesRow>

      {level1SectionId === 'under_hood' && (
        <TextWrapper>
          <HelperText />
        </TextWrapper>
      )}
      <TakePictureWrapper>
        <TakePhoto />
      </TakePictureWrapper>
    </>
  );
};

const ConfirmingPhotoView = () => {
  const { level1SectionId, imageIndex } = useCameraViewContext();
  const isTires = level1SectionId?.includes('tires');
  return (
    <>
      <ImagePreview>
        <VerticalControls />
        <DamageCheckbox />
        <Counter />
      </ImagePreview>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <NotesRow>
          <CameraNotes />
          {isTires && [0, 2, 3, 4].includes(imageIndex) && <CameraTiresGrade />}
        </NotesRow>
        <OpenQuestionsButton />
        <TakeNextButton />
      </div>
    </>
  );
};
