import { Field, FieldRenderProps, useField } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { TiresIconList } from './icons';
import { NotesIconComponent, BinIcon } from '../photos';
import { useRemoveSectionImageMutation } from 'services/api/vehicle-sections';
import toast from 'react-hot-toast';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 8px;
  padding: 0 8px 8px;
`;

const Image = styled.div<{ imageUrl: string; isPlaceholder: boolean }>`
  background-color: ${props => (props.isPlaceholder ? '#D9D9D9' : 'inherit')};
  background-image: ${({ imageUrl, isPlaceholder }) => (isPlaceholder ? 'none' : `url(${imageUrl})`)};
  background-size: 100% 100%;
  background-repeat: no-repeat;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  align-items: center;
  justify-content: center;
`;

const TopImageRow = styled.div`
  padding: 8px;
  display: flex;
  width: 100%;
  margin-bottom: auto;
  justify-content: space-between;
`;

export const TiresAllPhotos = () => {
  const { fields } = useFieldArray(`exterior.subsections.tires.images`);

  return (
    <Wrapper>
      {fields.map((field, i) => (
        <Field name={field}>
          {fieldProps => (
            <TiresImageComponent {...fieldProps} i={i} />
          )}
        </Field>
      ))}
    </Wrapper>
  );
};

const TiresImageComponent = ({ i, ...fieldProps }: FieldRenderProps<any, HTMLElement, any> & { i: number }) => {
  const [removeImageMutation] = useRemoveSectionImageMutation();

  const {
    input: { value: vehicleId }
  } = useField(`vehicleId`);

  const {
    input: { value: serverId }
  } = useField(`exterior.subsections.tires.id`);

  const { fields } = useFieldArray(`exterior.subsections.tires.images`);

  const handleRemove = async () => {
    const response = await removeImageMutation({
      vehicleId,
      sectionId: serverId,
      imageId: fieldProps.input.value.id
    });

    if ('data' in response) {
      toast.success('Image removed');
      fields.update(i, { ...fields.value[i], url: '', url_thumb: '' });
    } else {
      toast.error('Failed to remove image');
    }
  };

  return (
    <Image imageUrl={fieldProps.input.value.url} isPlaceholder={!fieldProps.input.value.url}>
      {!fieldProps.input.value.url && TiresIconList[i]}
      {fieldProps.input.value.url && (
        <TopImageRow>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={handleRemove}>
            <BinIcon />
          </div>
          <NotesIconComponent fieldName={fieldProps.input.name} />
        </TopImageRow>
      )}
    </Image>
  );
};
