import styled from 'styled-components';
import { useCameraViewContext } from '../../context';

const TakeImageButton = styled.div`
  width: 58px;
  height: 58px;
  background: rgba(255, 255, 255, 1);
  border-radius: 100%;
  outline: 3px solid rgba(255, 255, 255, 1);
  outline-offset: 5px;
`;

export const TakePhoto = () => {
  const { takeImage } = useCameraViewContext();
  return <TakeImageButton onClick={takeImage} />;
};
