import { Button } from '@material-ui/core';
import { useCameraViewContext } from 'pages/ListerVehicleUploadV2/CameraView/context';
import { useField } from 'react-final-form';
import styled from 'styled-components';
import { useSubmit } from './useSubmit';

const StyledButton = styled(Button)`
  box-shadow: 0px 4px 4px 0px #00000040;
  background: #225a91;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: none;
  height: 44px;
  border-radius: 6px;

  @media (orientation: landscape) {
    display: none;
  }
`;

export const SubmitButton = () => {
  const { renderText, handleSubmit } = useSubmit();

  return (
    <StyledButton color="primary" variant="contained" onClick={handleSubmit}>
      {renderText}
    </StyledButton>
  );
};
