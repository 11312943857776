import { AppBar, Icon, Toolbar, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { PageWrapper } from 'components';
import { useSearchParams } from 'hooks';
import { useHistory } from 'react-router-dom';

const TypographyBreadcrumbs = styled(Typography)`
  font-size: 16px;
  flex-grow: 1;
`;

const StyledAppBar = styled(AppBar)`
  background: #fff;
  top: 72px;
`;

const MuiIcon = styled(Icon)`
  display: flex;
  margin-right: 8px;
  color: #000;
`;

const viewToLabel: Record<string, string> = {
  sections_list: 'Vehicles',
  section_expanded_view: 'Sections'
};

export const Header = () => {
  const history = useHistory();

  const goBack = () => {
    return history.goBack();
  };

  return (
    <StyledAppBar position="fixed" color="default">
      <PageWrapper className="">
        <Toolbar disableGutters>
          <MuiIcon onClick={goBack}>arrow_back</MuiIcon>
          <TypographyBreadcrumbs variant="title">{'Vehicles'}</TypographyBreadcrumbs>
        </Toolbar>
      </PageWrapper>
    </StyledAppBar>
  );
};
