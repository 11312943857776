import styled from 'styled-components';
import { BinIcon } from 'components/Icons';
import { useCameraViewContext } from '../../context';
import { RetakeIcon } from '../icon';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background: rgba(8, 0, 0, 0.6);
  height: 44px;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;

  @media (orientation: landscape) {
    display: none;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  color: #fff;
`;

const IconWrapper = styled.div`
  display: flex;
`;

export const VerticalControls = () => {
  const { retakeImage, state } = useCameraViewContext();

  if (state === 'taking_photo') return null;

  return (
    <Wrapper>
      <ItemWrapper onClick={retakeImage}>
        <IconWrapper>
          <RetakeIcon />
        </IconWrapper>
        <Label>Retake</Label>
      </ItemWrapper>
      <ItemWrapper onClick={retakeImage}>
        <IconWrapper>
          <BinIcon />
        </IconWrapper>
        <Label>Remove</Label>
      </ItemWrapper>
    </Wrapper>
  );
};
