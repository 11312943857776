import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Select, Toolbar, AppBar, MenuItem, withWidth } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  BUYER_LINKS,
  GUEST_LINKS,
  SELLER_LINKS,
  ADMIN_LINKS,
  NOT_COMPLETED_REGISTRATION_LINKS,
  ADMIN_LINKS_MANAGER,
  ADMIN_LINKS_LISTER,
  ADMIN_LINKS_SALESMAN,
  ADMIN_LINKS_ADMIN_SELLER
} from '../../constants/links';
import { ADMIN_JOB_TITLES, roles, USER_STATUSES } from '../../constants';
import { userStateSelector } from '../../selectors';
import { LinksList } from './components';
import { getBackupToken } from '../../utils/auth';
import './index.scss';
import { getCurrentAuction, listLocations, locationsSetPage, setLocation, listVehicles } from '../../actions';

import LogoImg from '../../img/logo.svg';
import DropdownArrow from '../../img/down-arrow.svg';

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  margin-right: 24px;
  span {
    text-transform: none;
  }
  @media only screen and (max-width: 600px) {
    border-right: none;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 40px;
  background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, #c4c4c4 50%, rgba(196, 196, 196, 0) 100%);

  @media only screen and (max-width: 1025px) {
    display: none;
  }
`;

const StyledLocationHolder = styled.span`
  display: flex;
  align-items: center;
  margin-left: 24px;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

class Header extends Component {
  constructor() {
    super();
    this.state = {
      isDrawerOpen: false,
      isCountryDropdownOpen: false,
      locationsList: []
    };
  }

  componentDidMount() {
    const { fetchLocations, setPage, fetchCurrentAuction, fetchListVehicles } = this.props;
    setPage(0);
    fetchLocations();
    fetchCurrentAuction();
    fetchListVehicles({
      params: {
        mainPage: true,
        location_id: 1
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    const { currentLocation, setSelectedLocation } = this.props;
    if (!currentLocation && nextProps.locations?.length > 0) {
      this.setState({
        locationsList: nextProps.locations
      });
      setSelectedLocation(nextProps.locations[0]);
    }
  }

  toggleDrawer = () => {
    const { isDrawerOpen } = this.state;
    this.setState({ isDrawerOpen: !isDrawerOpen });
  };

  toggleCountryDropdown = () => {
    const { isCountryDropdownOpen } = this.state;
    this.setState({ isCountryDropdownOpen: !isCountryDropdownOpen });
  };

  handleChangeLocation = e => {
    const { setSelectedLocation } = this.props;
    setSelectedLocation(e.target.value);
  };

  renderLinks = () => {
    const { user, currentAuction, vehicles, location } = this.props;
    const { status, role } = user;
    const isAdminLoggedIn = getBackupToken();
    const { isDrawerOpen } = this.state;
    const { toggleDrawer } = this;
    const additionalProps = { isAdminLoggedIn, toggleDrawer, isDrawerOpen };
    const isRegistrationIsNotFinished = status === USER_STATUSES.NEED_TO_COMPLETE_SIGN_UP;
    let showLiveAuction = currentAuction && currentAuction.status === 'active';
    const isLoggedIn = user && user.id;

    if (isLoggedIn && showLiveAuction) {
      showLiveAuction = vehicles.length;
    } else if (showLiveAuction) {
      showLiveAuction = vehicles.length > 3;
    }

    if (role === roles.ADMIN) {
      showLiveAuction = currentAuction && currentAuction.status === 'active';
    } else if (location.pathname === '/live-auction') {
      showLiveAuction = true;
    }

    if (!role) {
      return <LinksList links={GUEST_LINKS} {...additionalProps} isActiveAuction={Boolean(showLiveAuction)} />;
      // eslint-disable-next-line no-else-return
    } else if (isRegistrationIsNotFinished && isAdminLoggedIn) {
      return (
        <LinksList
          links={NOT_COMPLETED_REGISTRATION_LINKS}
          {...additionalProps}
          isActiveAuction={Boolean(showLiveAuction)}
        />
      );
    } else if (role === 'buyer') {
      return <LinksList links={BUYER_LINKS} {...additionalProps} isActiveAuction={Boolean(showLiveAuction)} />;
      // eslint-disable-next-line no-else-return
    } else if (role === 'seller') {
      return <LinksList links={SELLER_LINKS} {...additionalProps} isActiveAuction={Boolean(showLiveAuction)} />;
    } else if (role === 'admin') {
      if (user.job_title === ADMIN_JOB_TITLES.ADMIN_SELLER)
        return <LinksList links={ADMIN_LINKS_ADMIN_SELLER} {...additionalProps} isActiveAuction={false} />;
      if (user.job_title === ADMIN_JOB_TITLES.LISTER)
        return <LinksList links={ADMIN_LINKS_LISTER} {...additionalProps} isActiveAuction={Boolean(showLiveAuction)} />;
      if (user.job_title === ADMIN_JOB_TITLES.SALESMAN)
        return (
          <LinksList links={ADMIN_LINKS_SALESMAN} {...additionalProps} isActiveAuction={Boolean(showLiveAuction)} />
        );
      if (user.job_title === ADMIN_JOB_TITLES.MANAGER)
        return (
          <LinksList links={ADMIN_LINKS_MANAGER} {...additionalProps} isActiveAuction={Boolean(showLiveAuction)} />
        );
      return <LinksList links={ADMIN_LINKS} {...additionalProps} isActiveAuction={Boolean(showLiveAuction)} />;
    }
    return null;
  };

  render() {
    const { currentLocation } = this.props;
    const { locationsList, isCountryDropdownOpen } = this.state;
    const { renderLinks } = this;
    return (
      <div>
        <AppBar elevation={0} color="initial" className="headerHolder">
          <Toolbar className="headerPanel">
            <StyledLink to="/">
              <LogoImg />
            </StyledLink>
            <Divider />
            <StyledLocationHolder>
              <Select
                value={currentLocation || ''}
                onChange={this.handleChangeLocation}
                inputProps={{
                  name: 'country',
                  id: 'country'
                }}
                className="select-container"
                classes={{
                  select: 'select'
                }}
                IconComponent={() => (
                  <span className={`select-icon ${isCountryDropdownOpen ? 'open' : ''}`}>
                    <DropdownArrow />
                  </span>
                )}
                MenuProps={{
                  classes: {
                    paper: 'country-menu-container'
                  },
                  onEnter: this.toggleCountryDropdown,
                  onExit: this.toggleCountryDropdown
                }}
              >
                {locationsList.map((item, i) => (
                  <MenuItem value={item} key={i}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </StyledLocationHolder>
            <span style={{ flexGrow: 1 }} />
            {renderLinks()}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentLocation: state.locations.currentLocation || null,
  locations: state.locations.itemsData.rows || [],
  locationsCount: +state.locations.itemsData.count || 0,
  user: userStateSelector(state),
  currentAuction: state.auctions.current,
  vehicles: state.vehicles.itemsData.rows || []
});

const mapDispatchToProps = dispatch => ({
  fetchLocations: params => dispatch(listLocations(params).request),
  setPage: page => dispatch(locationsSetPage(page)),
  setSelectedLocation: data => dispatch(setLocation(data)),
  fetchCurrentAuction: () => dispatch(getCurrentAuction().request),
  fetchListVehicles: params => dispatch(listVehicles(params).request)
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withWidth())(Header);
