import { Field, useField, useFormState } from 'react-final-form';
import styled from 'styled-components';
import { EmptyImage, PhotoComponent } from '../components/photos';
import { BaseSectionHeader } from '../components/ui';
import { useState } from 'react';
import { CollapseIcon } from '../../../components/Icons/CollapseIcon';
import { ExpandIcon } from '../../../components/Icons/ExpandIcon';
import { FieldArray } from 'react-final-form-arrays';
import { TiresAllPhotos } from '../components/tires/tiresAllPhotos';

type Props = {
  sectionId: string;
  collapsed?: boolean;
  theme?: 'grey' | 'white';
};

export const AllPhotosSectionWrapper = styled.div<{ withBorder?: boolean }>`
  display: flex;
  flex-direction: column;

  border-radius: 8px;
  outline: ${props => (props.withBorder ? '1px solid #3994de' : 'none')};
`;

export const AllPhotosHeader = styled(BaseSectionHeader)`
  background: #ffffff;

  & > span {
    font-size: 14px;
    font-weight: 700;
    line-height: 16.41px;
    color: #000;
  }
`;

const IconWrapper = styled.div`
  display: flex;
`;

export const PhotosWrapper = styled.div`
  background: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 7px 5px;
  padding: 0px 12px 16px 12px;
`;

const fillColor = 'rgba(153, 153, 153, 1)';

const themeOptions = {
  white: {
    background: '#fff',
    gradeBackground: '#2222220A'
  },
  grey: {
    background: '#F5F5F5',
    gradeBackground: '#fff'
  }
};

export const AllPhotosSection = ({ sectionId, collapsed = false, theme = 'grey' }: Props) => {
  console.log(sectionId, 'sectionIdzxc');
  const {
    input: { value: label }
  } = useField(`${sectionId}.label`);
  const [isExpanded, setIsExpanded] = useState(!collapsed);

  const toggleExpanded = () => {
    setIsExpanded(c => !c);
  };

  const { background } = themeOptions[theme];

  const withBorder = theme === 'white' && sectionId.includes('exterior');

  return (
    <AllPhotosSectionWrapper style={{ background }} withBorder={withBorder}>
      <AllPhotosHeader isExpanded={isExpanded} style={{ background }}>
        <IconWrapper onClick={toggleExpanded}>
          {isExpanded ? <CollapseIcon fillColor={fillColor} /> : <ExpandIcon fillColor={fillColor} />}
        </IconWrapper>
        <span>{`All ${label} Pictures`}</span>
      </AllPhotosHeader>
      {isExpanded &&
        (sectionId.includes('tires') ? (
          <TiresAllPhotos />
        ) : (
          <DefaultPhotosList background={background} sectionId={sectionId} />
        ))}
    </AllPhotosSectionWrapper>
  );
};

type DefaultPhotosListProps = {
  sectionId: string;
  background: string;
};

const DefaultPhotosList = ({ sectionId, background }: DefaultPhotosListProps) => {
  return (
    <PhotosWrapper style={{ background }}>
      <FieldArray name={`${sectionId}.images`}>
        {({ fields }) =>
          fields.map((name, i) => (
            <Field
              key={name}
              name={name}
              component={props => (
                <PhotoComponent
                  localRemove={() => fields.remove(i)}
                  withUploadLabel={false}
                  displaySize="md"
                  {...props}
                />
              )}
            />
          ))
        }
      </FieldArray>
      <EmptyImage />
    </PhotosWrapper>
  );
};
