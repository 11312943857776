import styled from 'styled-components';
import { useField } from 'react-final-form';
import ReactSelect from 'react-select';
import { useUpdateSectionImageMutation } from 'services/api/upload-vehicle';
import { useUpdateSectionImageNotesMutation } from 'services/api/vehicle-sections';
import { useTiresGrade } from 'pages/ListerVehicleUploadV2/hooks/useTiresGrade';

const StyledSelect = styled(ReactSelect)`
  .tires-grade-select__control {
    background-color: #f6f6f6;
    border-radius: 30px;
    border: 1px solid #99999966;
    width: 68px;
    height: 32px;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none !important; /* Remove blue outline */
  }

  .tires-grade-select__single-value {
    font-size: 12px;
  }

  .tires-grade-select__value-container {
    padding: 0px 8px;
  }

  .tires-grade-select__control:hover {
    border: 1px solid #99999966;
  }

  .tires-grade-select__control--is-focused {
    border: 1px solid #99999966 !important;
    box-shadow: none !important;
  }

  .tires-grade-select__single-value {
    color: #000;
    font-weight: bold;
  }

  .tires-grade-select__dropdown-indicator {
    color: #000 !important;
    padding: 8px;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .tires-grade-select__menu {
    background-color: #f6f6f6;
    border-radius: 8px;
  }

  .tires-grade-select__indicator-separator {
    display: none;
  }

  .tires-grade-select__option {
    color: #000;
    padding: 8px;
    font-size: 12px;
  }

  .tires-grade-select__option:hover {
    background-color: #f6f6f6;
  }
`;

const options = Array.from(new Array(15)).map((_, i) => ({
  label: i,
  value: i
}));

type Option = {
  label: number;
  value: number;
};

export const TiresGradeSelection = ({ sectionId }: { sectionId: string }) => {
  const [level1, _0, level2, _1] = sectionId.split('.');

  const { changeGradeBasedOnNumber, value: numericGrade, imageIndex } = useTiresGrade(sectionId);

  const {
    input: { value: id }
  } = useField(`${level1}.subsections.${level2}.images.${imageIndex}.id`);

  const {
    input: { value: vehicleId }
  } = useField(`vehicleId`);

  const {
    input: { value: serverId }
  } = useField(`${level1}.id`);

  const [updateSectionImage] = useUpdateSectionImageNotesMutation();

  const handleChange = async (o: Option) => {
    const response = await updateSectionImage({ imageId: id, vehicleId, grade: o.value, sectionId: serverId });
    if ('error' in response) {
      console.error(response.error);
    } else {
      changeGradeBasedOnNumber(o.value);
    }
  };

  return (
    <StyledSelect
      value={options.find(o => o.value === numericGrade)}
      onChange={handleChange}
      isSearchable={false}
      placeholder=""
      classNamePrefix={'tires-grade-select'}
      options={options}
    />
  );
};
