type Props = {
  isActive?: boolean;
};

export const NotesIcon = ({ isActive = false }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      {isActive && <rect width="32" height="32" rx="8" fill="#3994DE" fill-opacity="0.1" />}
      <path
        d="M11.8568 12.6504C12.0679 12.6504 12.2526 12.5712 12.4109 12.4129C12.5693 12.2546 12.6484 12.0698 12.6484 11.8587C12.6484 11.6476 12.5691 11.4629 12.4103 11.3046C12.2516 11.1462 12.0684 11.0671 11.8609 11.0671C11.6471 11.0671 11.4609 11.1464 11.3026 11.3052C11.1443 11.4639 11.0651 11.647 11.0651 11.8545C11.0651 12.0684 11.1443 12.2546 11.3026 12.4129C11.4609 12.5712 11.6457 12.6504 11.8568 12.6504ZM11.8568 16.7879C12.0654 16.7879 12.2496 16.7101 12.4091 16.5546C12.5687 16.399 12.6484 16.2143 12.6484 16.0004C12.6484 15.7865 12.5691 15.6004 12.4103 15.4421C12.2516 15.2837 12.0684 15.2046 11.8609 15.2046C11.6471 15.2046 11.4609 15.2837 11.3026 15.4421C11.1443 15.6004 11.0651 15.7865 11.0651 16.0004C11.0651 16.2143 11.1449 16.399 11.3044 16.5546C11.464 16.7101 11.6481 16.7879 11.8568 16.7879ZM11.8568 20.9337C12.0679 20.9337 12.2526 20.8545 12.4109 20.6962C12.5693 20.5379 12.6484 20.3532 12.6484 20.1421C12.6484 19.9309 12.5691 19.7462 12.4103 19.5879C12.2516 19.4296 12.0684 19.3504 11.8609 19.3504C11.6471 19.3504 11.4609 19.4298 11.3026 19.5885C11.1443 19.7473 11.0651 19.9304 11.0651 20.1379C11.0651 20.3518 11.1443 20.5379 11.3026 20.6962C11.4609 20.8545 11.6457 20.9337 11.8568 20.9337ZM8.73176 24.8504C8.30468 24.8504 7.93402 24.6933 7.61979 24.379C7.30555 24.0648 7.14844 23.6941 7.14844 23.2671V8.73372C7.14844 8.30663 7.30555 7.93597 7.61979 7.62174C7.93402 7.30751 8.30468 7.15039 8.73176 7.15039H19.3068C19.5236 7.15039 19.7303 7.19266 19.9268 7.27719C20.1233 7.36174 20.2944 7.47781 20.4401 7.62539L24.3734 11.5587C24.521 11.7044 24.6371 11.8756 24.7216 12.0721C24.8062 12.2686 24.8484 12.4752 24.8484 12.692V23.2671C24.8484 23.6941 24.6913 24.0648 24.3771 24.379C24.0629 24.6933 23.6922 24.8504 23.2651 24.8504H8.73176ZM8.73176 23.2671H23.2651V12.8837H19.9109C19.6887 12.8837 19.5005 12.8066 19.3464 12.6525C19.1922 12.4983 19.1151 12.3101 19.1151 12.0879V8.73372H8.73176V23.2671Z"
        fill={isActive ? '#3994DE' : '#999999'}
      />
    </svg>
  );
};
