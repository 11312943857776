export const SectionKeys: Array<[string, string[]]> = [
  [
    'under_hood',
    [
      'under_hood',
      'oil_condition',
      'coolant',
      'transmission_dipstick',
      'transmission_dipstick_smell',
      'emissions_control_sticker',
      'exhaust_deep_each_side_cat',
      'frame_fit_finish_under_hood',
      'under_hood_3_point_body_lines_paint_check',
      'head_gasket_condition',
      'noticeable_oil_leak',
      'under_hood_factory_equipment',
      'check_crankcase_pressure_blow_by'
    ]
  ],
  ['exterior', []],
  ['interior_questions', []],
  ['dive_code', []]
];

export const Level1Labels: Record<string, string> = {
  under_hood: 'Under Hood',
  exterior: 'Exterior',
  interior_questions: 'Interior',
  dive_code: 'Dive Code'
};

export const Level2Labels: Record<
  string,
  Record<string, { label: string; pictureLabel: string; labelParts: string[] }>
> = {
  interior_questions: {
    odb_check_smog_readiness: {
      label: 'OBD Check Smog Readiness',
      pictureLabel: 'OBD Check Smog Readiness',
      labelParts: ['OBD Check/', 'Smog Readiness']
    },
    title_issues: {
      label: 'Title Issues',
      pictureLabel: 'Title Issues',
      labelParts: ['Title Issues']
    },
    radio_turns_on: {
      label: 'Radio Turns On',
      pictureLabel: 'Radio Turns On',
      labelParts: ['Radio Turns', 'On']
    },
    interior_vehicle_odor: {
      label: 'Interior Vehicle Odor',
      pictureLabel: 'Interior Vehicle Odor',
      labelParts: ['Interior Vehicle', 'Odor']
    },
    power_equipment_checked: {
      label: 'Power Equipment Checked',
      pictureLabel: 'Power Equipment Checked',
      labelParts: ['Power Equipment', 'Checked']
    },
    check_engine_light: {
      label: 'Check Engine Light',
      pictureLabel: 'Check Engine Light',
      labelParts: ['Check Engine', 'Light']
    },
    abs_light: {
      label: 'Abs Light',
      pictureLabel: 'Abs Light',
      labelParts: ['Abs Light']
    },
    air_bag_light: {
      label: 'Air Bag Light',
      pictureLabel: 'Air Bag Light',
      labelParts: ['Air Bag Light']
    },
    any_other_lights_on: {
      label: 'Any Other Lights On',
      pictureLabel: 'Any Other Lights On',
      labelParts: ['Any Other', 'Lights On']
    },
    windshield: {
      label: 'Windshield',
      pictureLabel: 'Windshield',
      labelParts: ['Windshield']
    },
    keys: {
      label: 'Keys',
      pictureLabel: 'Keys',
      labelParts: ['Keys']
    },
    air_conditioning: {
      label: 'Air Conditioning',
      pictureLabel: 'Air Conditioning',
      labelParts: ['Air Conditioning']
    },
    heater: {
      label: 'Heater',
      pictureLabel: 'Heater',
      labelParts: ['Heater']
    }
  },
  under_hood: {
    under_hood: {
      label: 'Under Hood',
      pictureLabel: 'Under Hood',
      labelParts: ['Under Hood']
    },
    oil_condition: {
      label: 'Oil Condition',
      pictureLabel: 'Oil cap & stick',
      labelParts: ['Oil Condition']
    },
    coolant: {
      label: 'Coolant',
      pictureLabel: 'Coolant',
      labelParts: ['Coolant']
    },
    transmission_dipstick: {
      label: 'Transmission Dipstick',
      pictureLabel: 'Trans Dipstick',
      labelParts: ['Transmission', 'Dipstick']
    },
    transmission_dipstick_smell: {
      label: 'Transmission Dipstick Smell',
      pictureLabel: '',
      labelParts: ['Transmission Dipstick', 'Smell']
    },
    emissions_control_sticker: {
      label: 'Emissions Control Sticker',
      pictureLabel: 'Emissions Control Sticker',
      labelParts: ['Emissions', 'Control Sticker']
    },
    exhaust_deep_each_side_cat: {
      label: 'Exhaust deep each side (Cat)',
      pictureLabel: 'Exhaust deep each side (Cat)',
      labelParts: ['Exhaust deep', 'each side (Cat)']
    },
    frame_fit_finish_under_hood: {
      label: 'Frame fit & Finish under hood',
      pictureLabel: 'Frame fit & Finish under hood',
      labelParts: ['Frame fit &', 'Finish under hood']
    },
    under_hood_3_point_body_lines_paint_check: {
      label: 'Under Hood 3 Point Body Lines & Paint Check',
      pictureLabel: 'Under Hood 3 Point Body Lines & Paint Check',
      labelParts: ['Under Hood 3 Point', 'Body Lines & Paint Check']
    },
    head_gasket_condition: {
      label: 'Head Gasket Condition',
      pictureLabel: 'Head Gasket Condition',
      labelParts: ['Head Gasket', 'Condition']
    },
    noticeable_oil_leak: {
      label: 'Noticeable Oil Leak',
      pictureLabel: 'Noticeable Oil Leak',
      labelParts: ['Noticeable', 'Oil Leak']
    },
    under_hood_factory_equipment: {
      label: 'Under Hood Factory Equipment',
      pictureLabel: 'Under Hood Factory Equipment',
      labelParts: ['Under Hood', 'Factory Equipment']
    },
    check_crankcase_pressure_blow_by: {
      label: 'Check Crankcase Pressure, Blow-By',
      pictureLabel: 'Check Crankcase Pressure, Blow-By',
      labelParts: ['Check Crankcase', 'Pressure, Blow-By']
    }
  }
};
