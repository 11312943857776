import { useFieldArray } from 'react-final-form-arrays';
import { useCameraViewContext } from '../../context';
import styled, { keyframes } from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { Field, FieldInputProps, useField } from 'react-final-form';
import { CollapseIcon } from 'components/Icons/CollapseIcon';
import { ExpandIcon } from 'components/Icons/ExpandIcon';
import { BinIcon } from 'components/Icons';
import { useRemoveSectionImageMutation } from 'services/api/vehicle-sections';
import toast from 'react-hot-toast';
import { TiresImageCarousel } from 'pages/ListerVehicleUploadV2/components/tires/tiresImageCarousel';
import { useHorizontalScrollFade } from 'hooks';
import { useDamageImages } from 'pages/ListerVehicleUploadV2/hooks/useDamagedImages';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (orientation: landscape) {
    margin-top: 4px;
  }
`;

const Collapse = keyframes`
  0% { 
    clip-path: inset(0 0 0 0);
    opacity: 1;
    background: transparent;
  }
  1% {
    clip-path: inset(0 0 0 0);
    opacity: 1;
    background: rgba(0, 0, 0, 0.8);
  }
  100% { 
    clip-path: inset(0 0 100% 0);
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
  }
`;

const Expand = keyframes`
  0% { 
    clip-path: inset(0 0 100% 0);
    opacity: 0;
  }
  100% { 
    clip-path: inset(0 0 0 0);
    opacity: 1;
  }
`;

const ContainerWrapper = styled.div<{ isExpanded: boolean }>`
  position: relative;
  min-height: 202px;

  opacity: ${props => (props.isExpanded ? 1 : 0)};

  @media (orientation: landscape) {
    background: ${props => (props.isExpanded ? 'rgba(0, 0, 0, 0.8)' : 'transparent')};
    padding: 0 12px 4px calc(12px + env(safe-area-inset-left, 0));
  }

  &.collapse {
    animation: ${Collapse} 0.3s ease-in-out;
  }

  &.expand {
    animation: ${Expand} 0.3s ease-in-out;
  }

  @media (orientation: landscape) {
    min-height: 92px;
    animation: none !important;
  }
`;

const Container = styled.div<{ showAfterFade: boolean; showBeforeFade: boolean }>`
  margin: 0;
  padding: 0;
  overflow: hidden;
  overflow-x: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fill, 97px);
  grid-auto-columns: 97px;
  grid-template-rows: 1fr 1fr;

  @media (orientation: landscape) {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fill, 92px);
    grid-auto-columns: 92px;
  }

  &:before {
    content: '';
    display: ${props => (props.showBeforeFade ? 'block' : 'none')};
    top: 0;
    left: 0;
    width: 100px;
    position: absolute;
    z-index: 999;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  }

  &:after {
    content: '';
    display: ${props => (props.showAfterFade ? 'block' : 'none')};
    right: 0;
    top: 0;
    width: 100px;
    position: absolute;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Image = styled.div<{ imageUrl: string; isPlaceholder: boolean }>`
  background-color: ${props => (props.isPlaceholder ? '#DBDBDB33' : 'inherit')};
  background-image: ${({ imageUrl, isPlaceholder }) => (isPlaceholder ? 'none' : `url(${imageUrl})`)};
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 97px;
  max-height: 97px;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  @media (orientation: landscape) {
    border: 2px solid #999999;
    min-height: 92px;
    max-height: 92px;
  }
`;

const ControlsRow = styled.div`
  padding: 8px 24px 8px 24px;
  display: flex;
  gap: 16px;
  align-items: center;

  @media (orientation: landscape) {
    background: rgba(0, 0, 0, 0.8);
  }
`;

const ControlsLabel = styled.div`
  color: #cccccc;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
`;

const CountLabel = styled(ControlsLabel)`
  margin-left: auto;
`;

const IconWrapper = styled.div`
  display: flex;
`;

export const ImageListController = () => {
  const { level1SectionId, imageIndex } = useCameraViewContext();
  const { fields } = useFieldArray(`${level1SectionId}.images`, { subscription: { value: true, length: true } });
  const [isExpanded, setIsExpanded] = useState(true);
  const [additionalClassName, setAdditionalClassName] = useState('');

  const toggleExpanded = () => {
    if (isExpanded) {
      setAdditionalClassName('collapse');
    } else {
      setAdditionalClassName('expand');
    }
    setIsExpanded(curr => !curr);
  };

  return (
    <PageWrapper>
      <ControlsRow>
        <IconWrapper onClick={toggleExpanded}>
          {isExpanded ? <CollapseIcon fillColor={'#fff'} /> : <ExpandIcon fillColor={'#fff'} />}
        </IconWrapper>
        <ControlsLabel>Photos</ControlsLabel>
        <CountLabel>{`${imageIndex}/50`}</CountLabel>
      </ControlsRow>
      <ContainerWrapper
        isExpanded={isExpanded && fields.length! > 0}
        className={additionalClassName}
        onAnimationEnd={() => setAdditionalClassName('')}
      >
        {level1SectionId?.includes('tires') ? <TiresImageCarousel /> : <DefaultImageList />}
      </ContainerWrapper>
    </PageWrapper>
  );
};

export const DamagedListController = () => {
  const damagedImages = useDamageImages();

  const ref = useRef<HTMLDivElement>(null);

  const { showBeforeFade, showAfterFade } = useHorizontalScrollFade(ref, {
    beforeFadeThreshold: 50
  });

  return (
    <Container ref={ref} showBeforeFade={showBeforeFade} showAfterFade={showAfterFade}>
      {damagedImages.map(image => {
        if (!image.url) return null;
        return (
          <Image isPlaceholder={!image.url} imageUrl={image.url}>
            {/* <BinIconWrapper onClick={handleRemove}>
            <BinIcon />
          </BinIconWrapper> */}
          </Image>
        );
      })}
    </Container>
  );
};

const DefaultImageList = () => {
  const ref = useRef<HTMLDivElement>(null);

  const { level1SectionId } = useCameraViewContext();
  const { fields } = useFieldArray(`${level1SectionId}.images`, { subscription: { value: true, length: true } });

  const { showBeforeFade, showAfterFade } = useHorizontalScrollFade(ref, {
    beforeFadeThreshold: 50
  });

  return (
    <Container ref={ref} showBeforeFade={showBeforeFade} showAfterFade={showAfterFade}>
      {fields.map((field, i) => {
        if (!fields.value[i].url) return null;
        return (
          <Field name={field} key={field}>
            {fieldProps => <ImageComponent index={i} {...fieldProps.input} />}
          </Field>
        );
      })}
    </Container>
  );
};

const BinIconWrapper = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  top: 4px;
  right: 4.6px;
  & svg {
    width: 12px;
    height: 12px;
  }
`;

const ImageComponent = ({ index, ...input }: FieldInputProps<any, HTMLElement> & { index: number }) => {
  const { level1SectionId, removeImage } = useCameraViewContext();
  const { fields } = useFieldArray(`${level1SectionId}.images`);
  const [removeImageMutation] = useRemoveSectionImageMutation();

  const {
    input: { value: vehicleId }
  } = useField(`vehicleId`);

  const {
    input: { value: serverId }
  } = useField(`${level1SectionId}.id`);

  const handleRemove = async () => {
    const response = await removeImageMutation({
      vehicleId,
      sectionId: serverId,
      imageId: input.value.id
    });

    if ('data' in response) {
      toast.success('Image removed');
      fields.remove(index);
      removeImage();
    } else {
      toast.error('Failed to remove image');
    }
  };

  return (
    <Image isPlaceholder={!input.value.url} imageUrl={input.value.url}>
      <BinIconWrapper onClick={handleRemove}>
        <BinIcon />
      </BinIconWrapper>
    </Image>
  );
};
