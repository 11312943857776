import styled from 'styled-components';
import { Button, Dialog, Icon } from '@material-ui/core';
import { Level1Labels } from '../config';
import { useCameraViewContext } from '../CameraView/context';
import ErrorIcon from './error.svg';
import SuccessIcon from './success.svg';
import { useField } from 'react-final-form';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px;
`;

const StyledDialog = styled(Dialog)`
  z-index: 10000;

  .success-dialog {
    border-radius: 20px;
    background: #fff;
    margin: 18px;
    height: 387px;
    width: 100%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 10000;
  }

  @media (orientation: landscape) {
    .success-dialog {
      max-height: unset;
    }
  }
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const Title = styled.div`
  font-size: 22px;
  line-height: 25.78px;
  font-weight: 500;
  color: #000;
`;

const MessageTitle = styled.div<{ isSuccess: boolean }>`
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 23.44px;
  letter-spacing: 0%;
  margin-top: 35px;
  color: ${props => (props.isSuccess ? '#15b684' : '#AF2930')};
`;

const Message = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
  letter-spacing: 0%;
  color: #000;
  margin-top: 12px;
`;

const CarIconWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CompletedButton = styled(Button)`
  border-radius: 6px;
  text-transform: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 18.75px;
  text-align: center;
  color: #fff;
  width: 100%;
  height: 44px;
  margin-top: 26px;
`;

const successMessages = {
  title: 'Congratulation!',
  message: "You've successfully uploaded all pictures, and answered all questions."
};

const errorMessages = {
  title: 'Have you missed something',
  message: "Some questions are incomplete. Filling them in will give users a clearer picture of the car's condition!"
};

export const ConfirmDialog = () => {
  const {
    successErrorDialogOpened,
    closeSuccessErrorDialog,
    closeCameraView,
    level1SectionId,
    openCameraView
  } = useCameraViewContext();

  const close = () => {
    closeSuccessErrorDialog();
  };

  const isSuccess = successErrorDialogOpened === 'success';
  const { title, message } = isSuccess ? successMessages : errorMessages;


  const handleSubmit = () => {
    if (level1SectionId === 'under_hood') {
      close()
      openCameraView(`exterior.subsections.tires`, 0);
    } else {
      close();
      closeCameraView()
    };
  };


  const {
    input: { value: label }
  } = useField(`${level1SectionId}.label`);

  return (
    <StyledDialog
      onClose={close}
      onBackdropClick={close}
      open={!!successErrorDialogOpened}
      classes={{ paperScrollPaper: 'success-dialog' }}
    >
      <Wrapper>
        <HeaderRow>
          <Title>{level1SectionId?.includes('exterior') ? 'Exterior' : label}</Title>
          <Icon onClick={close}>close</Icon>
        </HeaderRow>
        <MessageTitle isSuccess={isSuccess}>{title}</MessageTitle>
        <CarIconWrapper>{isSuccess ? <SuccessIcon /> : <ErrorIcon />}</CarIconWrapper>
        <Message>{message}</Message>
        <CompletedButton onClick={handleSubmit} variant="contained" color="primary" type="button">
          Continue with next category
        </CompletedButton>
      </Wrapper>
    </StyledDialog>
  );
};
