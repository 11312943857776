import { Field, useField } from 'react-final-form';
import { usePatchVehicleSectionMutation } from 'services/api/vehicle-sections';
import styled from 'styled-components';

type Props = {
  sectionId: string;
  onSelect?: (grade: string) => void;
  background?: string;
};

const GradeRow = styled.div`
  display: flex;
  gap: 6.75px;
`;

const GradeButton = styled.div<{ selectedColor: string; isSelected?: boolean; background?: string }>`
  border: 1px solid #999999;
  border-radius: 8px;
  background: ${props => (props.isSelected ? props.selectedColor : props.background || '#fff')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  flex: 1 1 100%;
  font-weight: ${props => (props.isSelected ? 600 : 400)};
  line-height: 16px;
  color: ${props => (props.isSelected ? '#fff' : '#000')};
  height: 40px;
  text-transform: capitalize;
`;

const gradeColorMap: Record<string, string> = {
  poor: 'rgba(175, 41, 48, 1)',
  fair: 'rgba(243, 175, 103, 1)',
  good: 'rgba(71, 151, 71, 1)'
};

const possibleGrades = ['poor', 'fair', 'good'];

export const GradeField = ({ sectionId, background, onSelect = () => {} }: Props) => {
  const [saveMutation] = usePatchVehicleSectionMutation();
  const {
    input: { value: serverId }
  } = useField(`${sectionId}.id`);
  const {
    input: { value: vehicleId }
  } = useField(`vehicleId`);

  const save = async (grade: string) => {
    if (!vehicleId) return; // tmp to not break flow
    await saveMutation({
      sectionId: serverId,
      grade,
      vehicleId
    });
  };

  return (
    <Field name={`${sectionId}.grade`}>
      {fieldProps => (
        <GradeRow>
          {possibleGrades.map(grade => (
            <GradeButton
              background={background}
              selectedColor={gradeColorMap[grade]}
              isSelected={fieldProps.input.value === grade}
              onClick={() => {
                onSelect(grade);
                fieldProps.input.onChange(grade);
                save(grade);
              }}
            >
              {grade}
            </GradeButton>
          ))}
        </GradeRow>
      )}
    </Field>
  );
};
