import styled from 'styled-components';
import { Dialog, Icon } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useField } from 'react-final-form';
import { Level3Section } from '../Level3Section';
import { Level1Labels } from '../config';
import { useCameraViewContext } from '../CameraView/context';
import { SubmitButton } from './components/SubmitButton';
import { AllPhotosSection } from '../AllPhotosSection';
import { useSubmit } from './components/useSubmit';
import { useWindowSize } from 'usehooks-ts';
import { AllDamagedPhotos } from '../DamageSection';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  justify-content: space-between;
  padding: 24px;
`;

const StyledDialog = styled(Dialog)<{ isFullScreenMode: boolean }>`
  z-index: 10000;

  .vehicle-questions {
    border-radius: 20px;
    background: #fff;
    margin: ${props => (props.isFullScreenMode ? '18px' : '110px 18px')};
    margin: 18px;
    height: ${props => (props.isFullScreenMode ? 'calc(100vh - 36px)' : 'calc(100vh - 220px)')};
    width: 100%;
    transition: height 0.5s cubic-bezier(0.4, 0, 0.2, 1) !important;
    max-height: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 10000;
  }

  @media (orientation: landscape) {
    .vehicle-questions {
      height: 100vh;
    }
  }
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 500;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const InFormQuestionsDialog = () => {
  const { confirmDialogOpened, closeConfirmDialog } = useCameraViewContext();

  const { width = 0, height = 0 } = useWindowSize();

  const { handleSubmit } = useSubmit();

  const close = () => {
    closeConfirmDialog();
  };

  const onClose = () => {
    if (width > height) {
      handleSubmit();
    } else {
      close();
    }
  };

  return (
    <StyledDialog
      onClose={onClose}
      onBackdropClick={close}
      open={confirmDialogOpened}
      isFullScreenMode={false}
      classes={{ paperScrollPaper: 'vehicle-questions' }}
    >
      {confirmDialogOpened && <VehicleQuestionsDialogContent onClose={onClose} />}
    </StyledDialog>
  );
};

const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-y: scroll;
  max-height: calc(100vh - 360px);
  transition: max-height 0.5s cubic-bezier(0.4, 0, 0.2, 1) !important;

  @media (orientation: landscape) {
    max-height: calc(100vh - 80px);
  }
`;

const VehicleQuestionsDialogContent = ({ onClose }: { onClose: () => void }) => {
  const { level1SectionId } = useCameraViewContext();

  const {
    input: { value: formValues }
  } = useField(`${level1SectionId}.subsections`);

  if (level1SectionId?.startsWith('exterior')) {
    return <ExteriorDialogContent onClose={onClose} />;
  }

  return (
    <Wrapper>
      <HeaderRow>
        <Title>{Level1Labels[level1SectionId!]}</Title>
        <Icon onClick={onClose}>
          <Close />
        </Icon>
      </HeaderRow>
      <ContentWrapper>
        <QuestionsWrapper>
          <AllPhotosSection collapsed sectionId={level1SectionId!} />
          {Object.keys(formValues).map(key => (
            <Level3Section key={key} sectionId={`${level1SectionId}.subsections.${key}`} />
          ))}
        </QuestionsWrapper>

        <SubmitButton />
      </ContentWrapper>
    </Wrapper>
  );
};

const ExteriorDialogContent = ({ onClose }: { onClose: () => void }) => {
  const {
    input: { value: tires }
  } = useField(`exterior.subsections.tires`);
  const {
    input: { value: fitFinishFrame }
  } = useField(`exterior.subsections.fit_finish_frame`);
  const {
    input: { value: exterior }
  } = useField(`exterior.subsections.exterior`);
  return (
    <Wrapper>
      <HeaderRow>
        <Title>{'Exterior'}</Title>
        <Icon onClick={onClose}>
          <Close />
        </Icon>
      </HeaderRow>
      <ContentWrapper>
        <QuestionsWrapper>
          <AllPhotosSection collapsed sectionId={`exterior.subsections.tires`} />
          <AllPhotosSection collapsed sectionId={`exterior.subsections.fit_finish_frame`} />
          <AllPhotosSection collapsed sectionId={`exterior.subsections.exterior`} />
          <AllDamagedPhotos theme="grey" collapsed />
          {Object.keys(tires.subsections).map((key, i) => (
            <Level3Section key={key} index={i} sectionId={`exterior.subsections.tires.subsections.${key}`} />
          ))}
          {Object.keys(fitFinishFrame.subsections).map(key => (
            <Level3Section key={key} sectionId={`exterior.subsections.fit_finish_frame.subsections.${key}`} />
          ))}
          {Object.keys(exterior.subsections).map(key => (
            <Level3Section key={key} sectionId={`exterior.subsections.exterior.subsections.${key}`} />
          ))}
        </QuestionsWrapper>

        <SubmitButton />
      </ContentWrapper>
    </Wrapper>
  );
};
