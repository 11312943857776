import { BinIcon } from 'components/Icons';
import styled from 'styled-components';
import { useState } from 'react';
import { useCameraViewContext } from '../../context';
import { ChecklistIcon, RetakeIcon } from '../icon';

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  position: relative;
`;

const IconWrapper = styled.div<{ isActive?: boolean }>`
  display: flex;
  background: #000;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: ${props => (props.isActive ? '1px solid #fff' : 'none')};
`;

const ListIconWrapper = styled.div<{ isActive?: boolean }>`
  display: flex;
  background: #000;
  gap: 10px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 82px;
  top: 0;
`;

const ActiveListIcon = styled.div`
  position: absolute;
  left: 4px;
  top: 4px;
`;

const HelperTextWrapper = styled.div`
  position: absolute;
  left: 82px;
  top: 0;
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14.06px;
  color: #cccccc;
  position: absolute;
  top: 8px;
  left: 40px;
`;

export const HorizontalControls = () => {
  const { showHelperText, setShowHelperText } = useCameraViewContext();
  const toggleText = () => setShowHelperText(c => !c);

  const { retakeImage } = useCameraViewContext();

  return (
    <Wrapper>
      <IconWrapper onClick={retakeImage}>
        <BinIcon />
      </IconWrapper>
      <IconWrapper onClick={retakeImage}>
        <RetakeIcon />
      </IconWrapper>
      {showHelperText ? (
        <HelperTextWrapper>
          <ExtendedItemIcon />
          <ActiveListIcon onClick={toggleText}>
            <ChecklistIcon isActive />
          </ActiveListIcon>
          <Text>
            1.Under Hood 2.Oil Condition 3.Coolant 4.Transmission Dipstick 5. Transmission Dipstick Smell 6.Emissions
            Control Sticker 7.Exhaust deep each side (Cat) 8.Frame fit & Finish under hood 9. Under Hood 3 Point Body
            Lines & Paint Check 10.Head Gasket Condition 11.Noticeable Oil Leak 12.Under Hood Factory Equipment 13.Check
            Crankcase Pressure, Blow-By
          </Text>
        </HelperTextWrapper>
      ) : (
        <ListIconWrapper onClick={toggleText}>
          <ChecklistIcon />
        </ListIconWrapper>
      )}
    </Wrapper>
  );
};

const ExtendedItemIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="661" height="84" viewBox="0 0 661 84" fill="none">
    <g opacity="0.8">
      <mask id="path-1-inside-1_9936_36725" fill="white">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4 -0.000236686C1.79086 -0.00023659 -3.59349e-06 1.79062 -3.49692e-06 3.99976L-2.44785e-06 27.9998C-2.35128e-06 30.2089 1.79085 31.9998 3.99999 31.9998L30.1252 31.9998L30.1252 79.9999C30.1252 82.2091 31.9161 83.9999 34.1252 83.9999L656.988 84C659.198 84 660.988 82.2091 660.988 80L660.988 28.3058C660.996 28.2048 661 28.1028 661 27.9998L661 3.99976C661 1.79062 659.209 -0.000242438 657 -0.000242341L4 -0.000236686Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 -0.000236686C1.79086 -0.00023659 -3.59349e-06 1.79062 -3.49692e-06 3.99976L-2.44785e-06 27.9998C-2.35128e-06 30.2089 1.79085 31.9998 3.99999 31.9998L30.1252 31.9998L30.1252 79.9999C30.1252 82.2091 31.9161 83.9999 34.1252 83.9999L656.988 84C659.198 84 660.988 82.2091 660.988 80L660.988 28.3058C660.996 28.2048 661 28.1028 661 27.9998L661 3.99976C661 1.79062 659.209 -0.000242438 657 -0.000242341L4 -0.000236686Z"
        fill="black"
      />
      <path
        d="M4 -0.000236686L4 -1.00024L4 -0.000236686ZM30.1252 31.9998L31.1252 31.9998L31.1252 30.9998L30.1252 30.9998L30.1252 31.9998ZM30.1252 79.9999L29.1252 79.9999L30.1252 79.9999ZM34.1252 83.9999L34.1252 82.9999L34.1252 83.9999ZM656.988 84L656.988 83L656.988 84ZM660.988 28.3058L659.991 28.2305L659.988 28.2681L659.988 28.3058L660.988 28.3058ZM657 -0.000242341L657 0.999758L657 -0.000242341ZM0.999997 3.99976C0.999996 2.3429 2.34314 0.999763 4 0.999763L4 -1.00024C1.23858 -1.00024 -1 1.23833 -1 3.99976L0.999997 3.99976ZM0.999998 27.9998L0.999997 3.99976L-1 3.99976L-1 27.9998L0.999998 27.9998ZM3.99999 30.9998C2.34314 30.9998 0.999998 29.6566 0.999998 27.9998L-1 27.9998C-1 30.7612 1.23856 32.9998 3.99999 32.9998L3.99999 30.9998ZM30.1252 30.9998L3.99999 30.9998L3.99999 32.9998L30.1252 32.9998L30.1252 30.9998ZM31.1252 79.9999L31.1252 31.9998L29.1252 31.9998L29.1252 79.9999L31.1252 79.9999ZM34.1252 82.9999C32.4684 82.9999 31.1252 81.6568 31.1252 79.9999L29.1252 79.9999C29.1252 82.7613 31.3638 84.9999 34.1252 84.9999L34.1252 82.9999ZM656.988 83L34.1252 82.9999L34.1252 84.9999L656.988 85L656.988 83ZM659.988 80C659.988 81.6568 658.645 83 656.988 83L656.988 85C659.75 85 661.988 82.7614 661.988 80L659.988 80ZM659.988 28.3058L659.988 80L661.988 80L661.988 28.3058L659.988 28.3058ZM660 27.9998C660 28.0775 659.997 28.1544 659.991 28.2305L661.986 28.3812C661.995 28.2552 662 28.128 662 27.9998L660 27.9998ZM660 3.99976L660 27.9998L662 27.9998L662 3.99976L660 3.99976ZM657 0.999758C658.657 0.999758 660 2.3429 660 3.99976L662 3.99976C662 1.23834 659.761 -1.00024 657 -1.00024L657 0.999758ZM4 0.999763L657 0.999758L657 -1.00024L4 -1.00024L4 0.999763Z"
        fill="white"
        mask="url(#path-1-inside-1_9936_36725)"
      />
    </g>
  </svg>
);
