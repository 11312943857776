import { useFieldArray } from 'react-final-form-arrays';

export const useInitialCameraViewIndex = (fieldId: string) => {
  const { fields } = useFieldArray(`${fieldId}.images`, { subscription: { length: true, value: true } });
  if (fieldId.includes('damaged')) {
    return 0;
  }

  if (!fieldId.includes('tires') || !fields.value) {
    return fields.length || 0;
  }

  let index = -1;
  for (const i in fields.value.slice(0, 6)) {
    if (!fields.value[i].url) {
      index = Number(i);
      break;
    }
  }

  if (index === -1) {
    return fields.length || 0;
  }

  return index;
};
