import { useField } from 'react-final-form';

const typeToIndex: Record<string, number> = {
  driver_front: 0,
  vin_plate: 1,
  driver_rear: 2,
  passenger_rear: 3,
  passenger_front: 4,
  license_plate_registration: 5
};

export const useTiresGrade = (sectionId: string) => {
  const [level1, _0, level2, _1, level3] = sectionId.split('.');
  const imageIndex = typeToIndex[level3];
  const { input: imageGradeInput } = useField(`${level1}.subsections.${level2}.images.${imageIndex}.grade`);
  const { input: sectionInput } = useField(`${sectionId}.grade`);

  const changeGradeBasedOnNumber = (num: number) => {
    imageGradeInput.onChange(num);
    if (num <= 2) {
      return sectionInput.onChange('poor');
    }
    if (num === 3) {
      return sectionInput.onChange('fair');
    }

    return sectionInput.onChange('good');
  };

  return { changeGradeBasedOnNumber, value: imageGradeInput.value, imageIndex };
};
