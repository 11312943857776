import { Field, FieldRenderProps, useField } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { ImageField } from '../../../../services/api/upload-vehicle';
import { NotesIcon } from '../../icons/notes';
import { useActiveNotesField } from 'services/listerUploadVehicle';
import { useDispatch } from 'react-redux';
import { ImagePlaceholderIcon } from '../../../CreateVehicle/icons/ImagePlaceholder';
import { CompletedFieldIcon } from '../../icons/completedField';
import { openImageNotesDialog } from 'services/listerUploadVehicle/v2';
import { useRemoveSectionImageMutation } from 'services/api/vehicle-sections';
import toast from 'react-hot-toast';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1 1 40%;
`;

const UploadLabel = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
  color: #000;
`;

const Image = styled.div<{ imageUrl: string; isPlaceholder: boolean; size: 'lg' | 'md' }>`
  background-color: ${props => (props.isPlaceholder ? '#D9D9D9' : 'inherit')};
  background-image: ${({ imageUrl, isPlaceholder }) => (isPlaceholder ? 'none' : `url(${imageUrl})`)};
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: ${props => (props.size === 'lg' ? '244px' : 'auto')};
  max-height: ${props => (props.size === 'lg' ? '244px' : 'auto')};
  aspect-ratio: ${props => (props.size === 'md' ? '1.375 / 1' : 'auto')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

const CheckIconWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 99;

  svg {
    width: 8px;
    height: 8px;
  }
`;

const TopImageRow = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
`;

const Label = styled.div<{ displaySize: 'lg' | 'md' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  text-align: center;
  padding: 1px 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.41px;
  color: #fff;

  & > span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: ${props => (props.displaySize === 'md' ? '110px' : 'none')};
  }
`;

const NotesIconWrapper = styled.div`
  display: flex;
  margin-left: auto;
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;

  background: #fff;
`;

const NotesWrapper = styled.div`
  background: rgba(34, 34, 34, 0.04);
  padding: 8px;
  padding-right: 20px;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  color: #000;
  border-radius: 8px;
`;

const PlaceholderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

type Props = {
  sectionId: string;
};

export const PhotosView = ({ sectionId }: Props) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <FieldArray name={`${sectionId}.images`}>
          {({ fields }) =>
            fields.map((f, i) => (
              <Field
                localRemove={() => fields.remove(i)}
                name={f}
                key={f}
                component={props => <PhotoComponent {...props} />}
              />
            ))
          }
        </FieldArray>
      </ImageWrapper>
      <NotesComponent />
    </Wrapper>
  );
};

type PhotoComponentProps = FieldRenderProps<ImageField> & {
  displaySize?: 'lg' | 'md';
  withUploadLabel?: boolean;
  localRemove?: () => void;
};

export const EmptyImage = () => (
  <FieldContainer>
    <Image size={'md'} imageUrl={''} isPlaceholder={true}>
      <PlaceholderWrapper>
        <ImagePlaceholderIcon />
      </PlaceholderWrapper>
    </Image>
  </FieldContainer>
);

export const PhotoComponent = ({
  input,
  localRemove = () => {},
  displaySize = 'lg',
  withUploadLabel = true
}: PhotoComponentProps) => {
  const imageUrl = input.value.file ? URL.createObjectURL(input.value.file) : input.value.url;

  const isPlaceholder = !imageUrl;

  const {
    input: { value: vehicleId }
  } = useField('vehicleId');

  const [deleteImage] = useRemoveSectionImageMutation();

  const handleRemove = async () => {
    try {
      if (input.value.id) {
        await deleteImage({ vehicleId, imageId: input.value.id, sectionId: -1 });
        toast.success('Image removed');
      }
      localRemove();
    } catch (error) {
      toast.error(`Failed to remove image`);
    }
  };

  return (
    <FieldContainer>
      {withUploadLabel && <UploadLabel>Upload Photos</UploadLabel>}
      <Image size={displaySize} imageUrl={imageUrl} isPlaceholder={isPlaceholder}>
        <TopImageRow>
          {!isPlaceholder && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={handleRemove}>
              <BinIcon />
            </div>
          )}
          <NotesIconComponent fieldName={input.name} />
        </TopImageRow>
        {isPlaceholder && (
          <PlaceholderWrapper>
            <ImagePlaceholderIcon />
          </PlaceholderWrapper>
        )}
        {input.value.label && (
          <Label displaySize={displaySize}>
            <span>{input.value.label}</span>
          </Label>
        )}
      </Image>
    </FieldContainer>
  );
};

export const NotesIconComponent = ({ fieldName }: { fieldName: string }) => {
  const dispatch = useDispatch();

  const {
    input: { value }
  } = useField(`${fieldName}.notes`);

  const hasNotes = !!value;

  const openNotesDialog = () => {
    dispatch(openImageNotesDialog(fieldName));
  };

  return (
    <NotesIconWrapper onClick={openNotesDialog}>
      <NotesIcon isActive={hasNotes} />
      {hasNotes && (
        <CheckIconWrapper>
          <CompletedFieldIcon />
        </CheckIconWrapper>
      )}
    </NotesIconWrapper>
  );
};

const NotesComponent = () => {
  const activeNotesField = useActiveNotesField();
  if (!activeNotesField) return null;

  return (
    <NotesWrapper>
      <Field name={`${activeNotesField}.notes`}>{props => <span>{props.input.value}</span>}</Field>
    </NotesWrapper>
  );
};

export const BinIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect width="24" height="24" rx="8" fill="white" />
    <path
      d="M8.66406 17C8.2974 17 7.98351 16.8694 7.7224 16.6083C7.46128 16.3472 7.33073 16.0333 7.33073 15.6667V7C7.14184 7 6.98351 6.93611 6.85573 6.80833C6.72795 6.68056 6.66406 6.52222 6.66406 6.33333C6.66406 6.14444 6.72795 5.98611 6.85573 5.85833C6.98351 5.73056 7.14184 5.66667 7.33073 5.66667H9.9974C9.9974 5.47778 10.0613 5.31944 10.1891 5.19167C10.3168 5.06389 10.4752 5 10.6641 5H13.3307C13.5196 5 13.678 5.06389 13.8057 5.19167C13.9335 5.31944 13.9974 5.47778 13.9974 5.66667H16.6641C16.853 5.66667 17.0113 5.73056 17.1391 5.85833C17.2668 5.98611 17.3307 6.14444 17.3307 6.33333C17.3307 6.52222 17.2668 6.68056 17.1391 6.80833C17.0113 6.93611 16.853 7 16.6641 7V15.6667C16.6641 16.0333 16.5335 16.3472 16.2724 16.6083C16.0113 16.8694 15.6974 17 15.3307 17H8.66406ZM15.3307 7H8.66406V15.6667H15.3307V7ZM10.6641 14.3333C10.853 14.3333 11.0113 14.2694 11.1391 14.1417C11.2668 14.0139 11.3307 13.8556 11.3307 13.6667V9C11.3307 8.81111 11.2668 8.65278 11.1391 8.525C11.0113 8.39722 10.853 8.33333 10.6641 8.33333C10.4752 8.33333 10.3168 8.39722 10.1891 8.525C10.0613 8.65278 9.9974 8.81111 9.9974 9V13.6667C9.9974 13.8556 10.0613 14.0139 10.1891 14.1417C10.3168 14.2694 10.4752 14.3333 10.6641 14.3333ZM13.3307 14.3333C13.5196 14.3333 13.678 14.2694 13.8057 14.1417C13.9335 14.0139 13.9974 13.8556 13.9974 13.6667V9C13.9974 8.81111 13.9335 8.65278 13.8057 8.525C13.678 8.39722 13.5196 8.33333 13.3307 8.33333C13.1418 8.33333 12.9835 8.39722 12.8557 8.525C12.728 8.65278 12.6641 8.81111 12.6641 9V13.6667C12.6641 13.8556 12.728 14.0139 12.8557 14.1417C12.9835 14.2694 13.1418 14.3333 13.3307 14.3333Z"
      fill="#999999"
    />
  </svg>
);
