import { useEffect, useRef, useState } from 'react';

type Args = {
  beforeFadeThreshold?: number;
};

export const useHorizontalScrollFade = (ref: React.RefObject<HTMLDivElement>, { beforeFadeThreshold = 50 }: Args) => {
  const [scrollOffset, setScrollOffset] = useState(0);
  const [widthDiff, setWidthDiff] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setWidthDiff(ref.current.scrollWidth - ref.current.offsetWidth);
    }
  });

  useEffect(() => {
    const checkScrollPosition = (e: any) => {
      if (!ref.current) return;
      setScrollOffset(ref.current.scrollLeft);
    };
    if (ref && ref.current) {
      ref.current.addEventListener('scroll', checkScrollPosition, false);
      return () => ref.current?.removeEventListener('scroll', checkScrollPosition, false);
    }
  }, [ref.current]);

  return { showBeforeFade: scrollOffset > beforeFadeThreshold, showAfterFade: scrollOffset < widthDiff };
};
