import styled from 'styled-components';
import { useCameraViewContext } from '../../context';
import { Icon } from '@material-ui/core';
import { QuizIcon } from 'pages/ListerVehicleUploadV2/icons/quizIcon';

const Wrapper = styled.div`
  padding: 0px 16px;
`;

const StyledButton = styled.div`
  border-radius: 6px;
  width: 100%;
  background: #3994de;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  height: 44px;
  box-shadow: 0px 4px 4px 0px #00000040;
`;

const StyledLabel = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  line-height: 14.06px;
  text-decoration-line: underline;
`;

export const OpenQuestionsButton = () => {
  const { openConfirmDialog, state, level1SectionId, imageIndex, currentImageSrc } = useCameraViewContext();
  // const { input } = useField(`${level1SectionId}.images.${imageIndex}.url`);
  const handleClick = () => {
    // input.onChange(currentImageSrc);
    openConfirmDialog();
  };

  return (
    <Wrapper>
      <StyledButton onClick={handleClick}>
        <Icon>
          <QuizIcon />
        </Icon>
        <StyledLabel>Proceed to Questions</StyledLabel>
      </StyledButton>
    </Wrapper>
  );
};
