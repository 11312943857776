import styled from 'styled-components';
import { ExpandIcon } from '../../../components/Icons/ExpandIcon';
import { DragIndicator } from '@material-ui/icons';
import { useState } from 'react';
import { CollapseIcon } from '../../../components/Icons/CollapseIcon';
import { SubSectionsList } from '../SubSectionsList';
import { CameraIcon } from '../../ListerVehicleUpload/icons/Camera';
import { BaseSectionHeader } from '../components/ui';
import { useCameraViewContext } from '../CameraView/context';
import { useField } from 'react-final-form';
import { useInitialCameraViewIndex } from '../hooks/useInitialCameraViewIndex';

export const SectionWrapper = styled.div<{ isLevel2: boolean; isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: ${props => (props.isLevel2 ? '1px solid #566F8D' : 'none')};
  border-radius: ${props => (props.isLevel2 ? '8px' : 0)};
`;

export const Header = styled(BaseSectionHeader)<{ isLevel2?: boolean }>`
  background: ${props => (props.isLevel2 ? '#D7E7F3' : 'rgba(34, 90, 145, 1)')};
  padding-right: ${props => (props.isLevel2 ? '8px' : '16px')};

  & > span {
    color: ${props => (props.isLevel2 ? '#000' : '#fff')};
  }
`;

export const IconWrapper = styled.div<{ floatLeft?: boolean; isLevel2?: boolean }>`
  display: flex;
  color: ${props => (props.isLevel2 ? '#666666' : '#fff')};
  margin-left: ${props => (props.floatLeft ? 'auto' : 0)};
`;

type Props = {
  sectionId: string;
  isLevel2?: boolean;
};

export const Level1Section = ({ sectionId, isLevel2 = false }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    input: { value: label }
  } = useField(`${sectionId}.label`);

  const imageFieldId = sectionId === 'exterior' ? `${sectionId}.subsections.tires` : sectionId;

  const toggleExpanded = () => {
    setIsExpanded(c => !c);
  };

  const { openCameraView } = useCameraViewContext();

  const initialIndex = useInitialCameraViewIndex(imageFieldId);

  const onCameraClick = () => {
    openCameraView(imageFieldId, initialIndex);
  };

  const fillColor = isLevel2 ? '#666666' : '#fff';

  return (
    <SectionWrapper isExpanded={isExpanded} isLevel2={isLevel2}>
      <Header isLevel2={isLevel2} isExpanded={isExpanded}>
        <IconWrapper onClick={toggleExpanded}>
          {isExpanded ? <CollapseIcon fillColor={fillColor} /> : <ExpandIcon fillColor={fillColor} />}
        </IconWrapper>
        <span>{label}</span>
        <IconWrapper isLevel2={isLevel2} floatLeft onClick={onCameraClick}>
          <CameraIcon color={fillColor} />
        </IconWrapper>
        <IconWrapper isLevel2={isLevel2} id={`activator-${sectionId}`}>
          <DragIndicator />
        </IconWrapper>
      </Header>

      {isExpanded && <SubSectionsList sectionId={sectionId} />}
    </SectionWrapper>
  );
};
