import React, { useState } from 'react';
import { useCameraViewContext } from '../../context';
import styled from 'styled-components';

const Wrapper = styled.div<{ imageUrl: string }>`
  width: 100%;
  height: auto;
  aspect-ratio: 2 / 1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  display: flex;
  flex-direction: column;
  position: relative;

  @media (orientation: landscape) {
    background-size: contain;
    background-position: center;
    aspect-ratio: auto;
    height: 100%;
  }
`;

type Props = {
  children: React.ReactNode;
};

export const ImagePreview = ({ children }: Props) => {
  const { currentImageSrc } = useCameraViewContext();

  if (!currentImageSrc) return null;

  return <Wrapper imageUrl={currentImageSrc}>{children}</Wrapper>;
};
