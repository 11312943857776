import { useCameraViewContext } from 'pages/ListerVehicleUploadV2/CameraView/context';
import { useField } from 'react-final-form';

const getAllSubsections = (iterator: any, res: any[]) => {
  for (const val of Object.values<any[]>(iterator)) {
    if ('subsections' in val && Object.keys(val.subsections || {}).length > 0) {
      getAllSubsections(val.subsections, res);
    } else {
      res.push(val);
    }
  }

  return res;
};

const getAllImages = (iterator: any, res: any[]) => {
  for (const val of Object.values<any[]>(iterator)) {
    if ('subsections' in val && 'images' in val) {
      res.push(...(val.images as any));
      getAllImages(val.subsections, res);
    }
  }

  return res;
};

export function useSubmit() {
  const { level1SectionId, closeConfirmDialog, openSuccessErrorDialog } = useCameraViewContext();
  const {
    input: { value }
  } = useField(level1SectionId?.includes('exterior') ? 'exterior' : level1SectionId!, {
    subscription: { value: true }
  });

  console.log(value, 'use submit value');

  const subsections = getAllSubsections({ key: value }, []);
  console.log(subsections, 'subs');

  const images = getAllImages({ key: value }, []);

  const numberOfFilledSections = subsections.reduce((acc: number, curr: any) => {
    if (!!curr.grade) {
      return acc + 1;
    }

    return acc;
  }, 0);

  const renderText = (() => {
    if (numberOfFilledSections === 0) {
      return 'Skip';
    }
    if (numberOfFilledSections === subsections.length && images.length > 0) {
      return 'Save';
    }
    return 'Skip & Save';
  })();

  const handleSubmit = () => {
    // closeCameraView();
    closeConfirmDialog();
    if (numberOfFilledSections === subsections.length && images.length > 0) {
      openSuccessErrorDialog('success');
    } else {
      openSuccessErrorDialog('error');
    }
  };

  return { handleSubmit, renderText };
}
