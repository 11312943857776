export const AddImageIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M9 25C8.45 25 7.97917 24.8042 7.5875 24.4125C7.19583 24.0208 7 23.55 7 23V9C7 8.45 7.19583 7.97917 7.5875 7.5875C7.97917 7.19583 8.45 7 9 7H16.45C16.7833 7 17.0375 7.14583 17.2125 7.4375C17.3875 7.72917 17.4083 8.04167 17.275 8.375C17.1917 8.64167 17.125 8.90833 17.075 9.175C17.025 9.44167 17 9.71667 17 10C17 11.3833 17.4875 12.5625 18.4625 13.5375C19.4375 14.5125 20.6167 15 22 15C22.2833 15 22.5583 14.975 22.825 14.925C23.0917 14.875 23.3583 14.8083 23.625 14.725C23.9583 14.6083 24.2708 14.6333 24.5625 14.8C24.8542 14.9667 25 15.2167 25 15.55V23C25 23.55 24.8042 24.0208 24.4125 24.4125C24.0208 24.8042 23.55 25 23 25H9ZM10 21H22L18.25 16L15.25 20L13 17L10 21ZM22 13C21.7167 13 21.4792 12.9042 21.2875 12.7125C21.0958 12.5208 21 12.2833 21 12V11H20C19.7167 11 19.4792 10.9042 19.2875 10.7125C19.0958 10.5208 19 10.2833 19 10C19 9.71667 19.0958 9.47917 19.2875 9.2875C19.4792 9.09583 19.7167 9 20 9H21V8C21 7.71667 21.0958 7.47917 21.2875 7.2875C21.4792 7.09583 21.7167 7 22 7C22.2833 7 22.5208 7.09583 22.7125 7.2875C22.9042 7.47917 23 7.71667 23 8V9H24C24.2833 9 24.5208 9.09583 24.7125 9.2875C24.9042 9.47917 25 9.71667 25 10C25 10.2833 24.9042 10.5208 24.7125 10.7125C24.5208 10.9042 24.2833 11 24 11H23V12C23 12.2833 22.9042 12.5208 22.7125 12.7125C22.5208 12.9042 22.2833 13 22 13Z"
      fill="#666666"
    />
  </svg>
);

export const SelectedAddImageIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M9 25C8.45 25 7.97917 24.8042 7.5875 24.4125C7.19583 24.0208 7 23.55 7 23V9C7 8.45 7.19583 7.97917 7.5875 7.5875C7.97917 7.19583 8.45 7 9 7H16.45C16.7833 7 17.0375 7.14583 17.2125 7.4375C17.3875 7.72917 17.4083 8.04167 17.275 8.375C17.1917 8.64167 17.125 8.90833 17.075 9.175C17.025 9.44167 17 9.71667 17 10C17 11.3833 17.4875 12.5625 18.4625 13.5375C19.4375 14.5125 20.6167 15 22 15C22.2833 15 22.5583 14.975 22.825 14.925C23.0917 14.875 23.3583 14.8083 23.625 14.725C23.9583 14.6083 24.2708 14.6333 24.5625 14.8C24.8542 14.9667 25 15.2167 25 15.55V23C25 23.55 24.8042 24.0208 24.4125 24.4125C24.0208 24.8042 23.55 25 23 25H9ZM10 21H22L18.25 16L15.25 20L13 17L10 21ZM22 13C21.7167 13 21.4792 12.9042 21.2875 12.7125C21.0958 12.5208 21 12.2833 21 12V11H20C19.7167 11 19.4792 10.9042 19.2875 10.7125C19.0958 10.5208 19 10.2833 19 10C19 9.71667 19.0958 9.47917 19.2875 9.2875C19.4792 9.09583 19.7167 9 20 9H21V8C21 7.71667 21.0958 7.47917 21.2875 7.2875C21.4792 7.09583 21.7167 7 22 7C22.2833 7 22.5208 7.09583 22.7125 7.2875C22.9042 7.47917 23 7.71667 23 8V9H24C24.2833 9 24.5208 9.09583 24.7125 9.2875C24.9042 9.47917 25 9.71667 25 10C25 10.2833 24.9042 10.5208 24.7125 10.7125C24.5208 10.9042 24.2833 11 24 11H23V12C23 12.2833 22.9042 12.5208 22.7125 12.7125C22.5208 12.9042 22.2833 13 22 13Z"
      fill="#3994DE"
    />
    <rect width="32" height="32" rx="8" fill="#3994DE" fill-opacity="0.1" />
  </svg>
);
