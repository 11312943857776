import { useField } from 'react-final-form';
import styled from 'styled-components';
import { useCameraViewContext } from '../../context';

const Button = styled.div`
  border: 1px solid #ffffff66;
  width: 70px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.7);

  & span {
    font-weight: 400;
    font-size: 18px;
    line-height: 21.09px;
    color: #74c0ff;
  }
`;

export const HorizontalNextButton = () => {
  const { level1SectionId, changeIndex, currentImageSrc, imageIndex } = useCameraViewContext();

  const { input } = useField(`${level1SectionId}.images.${imageIndex}.url`);

  const handleNext = () => {
    input.onChange(currentImageSrc);
    changeIndex();
  };

  return (
    <Button onClick={handleNext}>
      <span>Next</span>
    </Button>
  );
};
