import styled from 'styled-components';
import { useCameraViewContext } from '../../context';
import { ChecklistIcon } from '../icon';

const IconWrapper = styled.div<{ isActive: boolean }>`
  position: absolute;
  right: 16px;
  bottom: 76px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: ${props => (props.isActive ? '3px solid #fff' : '3px solid #999999')};
  border-radius: 100%;
  z-index: 9999;
`;

export const HelperTextVerticalToggler = () => {
  const { showHelperText, setShowHelperText } = useCameraViewContext();

  const toggleText = () => setShowHelperText(c => !c);

  return (
    <IconWrapper isActive={showHelperText} onClick={toggleText}>
      <ChecklistIcon isActive={showHelperText} />
    </IconWrapper>
  );
};
