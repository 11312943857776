import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

type ListerUploadVehicleState = {
  openedQuestionsDialog: number | null;
  openedVinDialog: number | null;
  openedCarInfoDialog: number | null;
  openedImageNotesDialog: string | null;
  vehicleSections: Record<number, any>;
}

const initialState: ListerUploadVehicleState = {
  openedQuestionsDialog: null,
  openedCarInfoDialog: null,
  openedVinDialog: null,
  openedImageNotesDialog: null,
  vehicleSections: {}
};

const listerUploadVehicleV2Slice = createSlice({
  name: 'listerUploadVehicleV2',
  initialState,
  reducers: {
    openQuestionsDialog: (state, action: PayloadAction<number>) => {
      state.openedQuestionsDialog = action.payload
    },
    closeQuestionsDialog: (state) => {
      state.openedQuestionsDialog = null
    },
    openVinDialog: (state, action: PayloadAction<number>) => {
      state.openedVinDialog = action.payload
    },
    closeVinDialog: (state) => {
      state.openedVinDialog = null
    },
    openCarInfoDialog: (state, action: PayloadAction<number>) => {
      state.openedCarInfoDialog = action.payload
    },
    closeCarInfoDialog: (state) => {
      state.openedCarInfoDialog = null
    },
    openImageNotesDialog: (state, action: PayloadAction<string>) => {
      state.openedImageNotesDialog = action.payload
    },
    closeImageNotesDialog: (state) => {
      state.openedImageNotesDialog = null
    },
    updateVehicleSections: (state, action: PayloadAction<{ id: number, data: any}>) => {
      state.vehicleSections[action.payload.id] = {
        ...(state.vehicleSections[action.payload.id] || {}),
        ...action.payload.data
      }
    }
  }
});

export const useOpenedQuestionsDialog = () => {
  const openedQuestionsDialog = useSelector<{ listerUploadVehicleV2: ListerUploadVehicleState }, number | null>((state) => state.listerUploadVehicleV2.openedQuestionsDialog)
  return openedQuestionsDialog
}

export const useVehicleSections = () => {
  const data = useSelector<{ listerUploadVehicleV2: ListerUploadVehicleState }, any>((state) => state.listerUploadVehicleV2.vehicleSections)
  return data
}

export const useVinDialogOpened = () => {
  const isOpen = useSelector<{ listerUploadVehicleV2: ListerUploadVehicleState }, number | null>((state) => state.listerUploadVehicleV2.openedVinDialog)
  return isOpen
}

export const useCarInfoDialogOpened = () => {
  const isOpen = useSelector<{ listerUploadVehicleV2: ListerUploadVehicleState }, number | null>((state) => state.listerUploadVehicleV2.openedCarInfoDialog)
  return isOpen
}

export const useImageNotesDialogOpened = () => {
  const isOpen = useSelector<{ listerUploadVehicleV2: ListerUploadVehicleState }, string | null>((state) => state.listerUploadVehicleV2.openedImageNotesDialog)
  return isOpen
}

export const {
  openQuestionsDialog,
  closeQuestionsDialog,
  updateVehicleSections,
  openCarInfoDialog,
  openVinDialog,
  closeCarInfoDialog,
  closeVinDialog,
  openImageNotesDialog,
  closeImageNotesDialog
} = listerUploadVehicleV2Slice.actions;

export default listerUploadVehicleV2Slice;
