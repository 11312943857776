import { useState } from 'react';
import styled from 'styled-components';
import { CollapseIcon } from '../../../components/Icons/CollapseIcon';
import { ExpandIcon } from '../../../components/Icons/ExpandIcon';
import { AddImageIcon, SelectedAddImageIcon } from '../../CreateVehicle/icons/AddImageIcon';
import { DragIndicator } from '../../CreateVehicle/icons/DragIndicator';
import { useField } from 'react-final-form';
import { GradeField } from '../components/grade';
import { PhotosView } from '../components/photos';
import { CheckboxQuestions } from '../components/checkbox/CheckboxField';
import { Level2Labels } from '../config';
import { NotesIcon } from '../icons/notes';
import { CompletedFieldIcon } from '../icons/completedField';
import { NotesField } from '../components/notes';
import { TiresGradeSelection } from '../components/tires/gradeSelection';

type Props = {
  sectionId: string;
  theme?: 'white' | 'grey';
  index?: number;
};

const Wrapper = styled.div<{ withBorder?: boolean; background: string }>`
  background: ${props => props.background};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 8px 9px 8px;
  width: 100%;
  outline: ${props => (props.withBorder ? '1px solid #3994de' : 'none')};
`;

const IconWrapper = styled.div<{ withAutoMargin?: boolean }>`
  display: flex;
  position: relative;
  margin-left: ${props => (props.withAutoMargin ? 'auto' : 0)};
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0px;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 14.06px;
  color: #000;
  margin-left: 8px;
`;

const fillColor = 'rgba(153, 153, 153, 1)';

const AutoCheckButton = styled.div`
  background: #3994de;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.06px;
  color: #fff;
  height: 32px;
  width: 100%;
`;

const CheckIconWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 3px;
  right: 4px;
  z-index: 99;
`;

const themeOptions = {
  white: {
    background: '#fff',
    gradeBackground: '#2222220A'
  },
  grey: {
    background: '#F5F5F5',
    gradeBackground: '#fff'
  }
};

export const Level3Section = ({ sectionId, theme = 'grey', index }: Props) => {
  const { background, gradeBackground } = themeOptions[theme];

  const [isExpanded, setIsExpanded] = useState(true);
  const [isNotesFieldOpen, setIsNotesFieldOpen] = useState(false);

  const [isPhotoMode, setPhotoMode] = useState(false);

  const toggleNotes = () => setIsNotesFieldOpen(curr => !curr);
  const toggleExpanded = () => setIsExpanded(curr => !curr);
  const togglePhotoMode = () => setPhotoMode(curr => !curr);

  const handleNotesClick = () => {
    if (isPhotoMode) {
      setPhotoMode(false);
      setIsNotesFieldOpen(true);
    } else {
      toggleNotes();
    }
  };

  const { input: notesInput } = useField(`${sectionId}.notes`);

  const onGradeSelect = (grade: string) => {
    if (grade !== 'good') {
      setIsNotesFieldOpen(true);
    }
  };

  const {
    input: { value: label }
  } = useField(`${sectionId}.label`);

  const showGradeSelection = sectionId?.includes('tires') && [0, 1, 2, 3].includes(index!);

  const withBorder = theme === 'white' && sectionId?.includes('exterior');

  return (
    <Wrapper withBorder={withBorder} background={background}>
      <HeaderRow>
        <IconWrapper onClick={toggleExpanded}>
          {isExpanded ? <CollapseIcon fillColor={fillColor} /> : <ExpandIcon fillColor={fillColor} />}
        </IconWrapper>
        <Title>{label}</Title>
        {showGradeSelection && (
          <IconWrapper withAutoMargin>
            <TiresGradeSelection sectionId={sectionId} />
          </IconWrapper>
        )}
        <IconWrapper withAutoMargin={!showGradeSelection} onClick={handleNotesClick}>
          <NotesIcon isActive={isNotesFieldOpen && !isPhotoMode && isExpanded} />
          {notesInput.value && (
            <CheckIconWrapper>
              <CompletedFieldIcon />
            </CheckIconWrapper>
          )}
        </IconWrapper>
        <IconWrapper>
          <DragIndicator />
        </IconWrapper>
      </HeaderRow>
      {isExpanded && !isPhotoMode && (
        <GeneralSection
          gradeBackground={gradeBackground}
          sectionId={sectionId}
          isNotesFieldOpen={isNotesFieldOpen}
          onGradeSelect={onGradeSelect}
        />
      )}
      {isExpanded && isPhotoMode && <PhotosView sectionId={sectionId} />}
    </Wrapper>
  );
};

const GeneralSection = ({
  sectionId,
  isNotesFieldOpen,
  onGradeSelect,
  gradeBackground
}: {
  sectionId: string;
  isNotesFieldOpen: boolean;
  onGradeSelect: (grade: string) => void;
  gradeBackground: string;
}) => {
  const subSectionId = sectionId.split('.')[2];
  const renderAutoCheckReport = subSectionId === 'title_issues';
  const renderCheckboxes = ['title_issues'].includes(subSectionId);

  return (
    <>
      {isNotesFieldOpen && <NotesField sectionId={sectionId} />}
      {!isNotesFieldOpen && <div />}
      <GradeField sectionId={sectionId} onSelect={onGradeSelect} background={gradeBackground} />
      {renderAutoCheckReport && <AutoCheckButton>View AutoCheck Report</AutoCheckButton>}
      {renderCheckboxes && <CheckboxQuestions sectionId={sectionId} />}
    </>
  );
};
