import { useWindowSize } from 'usehooks-ts';
import { HorizontalView } from './HorizontalView';
import { VerticalView } from './VerticalView';

export const CameraView = () => {
  const { width = 0, height = 0 } = useWindowSize();
  if (width > height) {
    return <HorizontalView />;
  }

  return <VerticalView />;
};
