import styled from 'styled-components';
import { useCameraViewContext } from '../../context';

const Text = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14.06px;
  color: #cccccc;
`;

export const HelperText = () => {
  const { showHelperText } = useCameraViewContext();
  if (!showHelperText) {
    return null;
  }

  return (
    <Text>
      1.Under Hood 2.Oil Condition 3.Coolant 4.Transmission Dipstick 5. Transmission Dipstick Smell 6.Emissions Control
      Sticker 7.Exhaust deep each side (Cat) 8.Frame fit & Finish under hood 9. Under Hood 3 Point Body Lines & Paint
      Check 10.Head Gasket Condition 11.Noticeable Oil Leak 12.Under Hood Factory Equipment 13.Check Crankcase Pressure,
      Blow-By
    </Text>
  );
};
